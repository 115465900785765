// TODO: Replace this with a library like https://mui.com/material-ui/react-autocomplete/ or https://react-select.com/
import { UseFormRegisterReturn } from "react-hook-form";

import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";

import { Select } from "@riffs/Forms";

import { ACOUSTIC_GUITAR } from "../constants/instruments";

import { useInstrumentSelectQuery } from "@graphql";

type Props = {
  registerProps: UseFormRegisterReturn;
  removeInstruments?: Array<string>;
  disabled?: boolean;
  error?: string;
  size?: string;
  value?: string;
  className?: string;
  hidden?: boolean;
  labelText?: string;
};

export const InstrumentSelect: React.FC<Props> = ({
  registerProps,
  removeInstruments,
  disabled,
  error,
  size,
  value,
  className,
  hidden,
  labelText = "Instrument",
}) => {
  const { data: instrumentsData, loading: loadingInstruments } =
    useInstrumentSelectQuery();
  const sortedInstrumentData = sortBy(instrumentsData?.instruments, "name");
  const removeInstrumentMap = keyBy(removeInstruments || []);
  const instruments = sortedInstrumentData.filter(
    (instrument) => !removeInstrumentMap[instrument.id],
  );
  return (
    <Select
      {...registerProps}
      id="instrument"
      labelText={labelText}
      errorMessage={error}
      disabled={disabled}
      size={size}
      defaultValue={value ? undefined : ""}
      value={value}
      className={className}
      hidden={hidden}
    >
      <option disabled value="">
        Select an instrument
      </option>

      {loadingInstruments && (
        <option key="loading" disabled>
          Loading instruments...
        </option>
      )}

      {(instruments || [])
        .filter((instrument) => instrument.id !== ACOUSTIC_GUITAR)
        .map((instrument) => {
          return (
            <option key={instrument.id} value={instrument.id}>
              {instrument.name}
            </option>
          );
        })}
    </Select>
  );
};
