import { type ButtonHTMLAttributes } from "react";

// https://stackoverflow.com/a/72892190/
import { twMerge } from "tailwind-merge";

type LinkStyledButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const LinkStyleButton = (props: LinkStyledButtonProps) => {
  const { children, className, ...restProps } = props;

  return (
    <button
      type="button"
      {...restProps}
      className={twMerge(
        "text-blue-500 dark:text-blue-400 text-sm",
        "hover:underline disabled:hover:no-underline",
        className,
      )}
    >
      {children}
    </button>
  );
};

export default LinkStyleButton;
