import { useCallback, useEffect, useState } from "react";

import { signIn, useSession } from "next-auth/react";

import { SessionStatus } from "./AuthGatekeeper";

// if it's been at least X seconds since we last renewed our token, let's preemptively refresh it
const REFRESH_ACCESS_TOKEN_AFTER_SECONDS = 15 * 60; // 15 minutes

// if our token will expire in the next X seconds, let's preemptively refresh it
const REFRESH_ACCESS_TOKEN_BEFORE_EXPIRATION_SECONDS = 30 * 60; // 30 minutes

const ATTEMPTS_EVERY_SECONDS = 30;

export const AuthRefresher = () => {
  const session = useSession();
  const [nextAttemptAt, setNextAttemptAt] = useState<number>();

  const check = useCallback(() => {
    if (!session.data) {
      return;
    }

    if (session.status !== SessionStatus.AUTHENTICATED) {
      return;
    }

    // we don't have a regular session (i.e. we have a passwordResetSession), so do nothing
    if (!session.data || !("accessToken" in session.data)) {
      return;
    }

    if (nextAttemptAt && Date.now() < nextAttemptAt) {
      return;
    }

    const now = Date.now();
    // it's been long enough, let's preemptivtely refresh our token
    // or our token is getting close to expiring,
    // so lets preemptively refresh it so we dont have to block
    if (
      session.data.accessTokenIssuedAt +
        REFRESH_ACCESS_TOKEN_AFTER_SECONDS * 1000 <
        now ||
      new Date(session.data.expires).getTime() <
        now + REFRESH_ACCESS_TOKEN_BEFORE_EXPIRATION_SECONDS
    ) {
      setNextAttemptAt(Date.now() + ATTEMPTS_EVERY_SECONDS * 1000);
      setTimeout(() => {
        console.debug("[auth] refreshing tokens");
        signIn("refreshToken", { redirect: false })
          .then((resp) => {
            if (!resp?.ok || resp?.error) {
              console.error("[auth] Failed to refresh tokens", resp?.error);
              return;
            }
            if (resp?.ok) {
              console.debug("[auth] tokens refresh");
            }
          })
          .catch((err) => {
            console.error("[auth] refreshToken operation failed", err);
          });
      }, 50);
    }
  }, [session, nextAttemptAt]);

  useEffect(() => {
    // we check this more frequently than the actual refresh period, because too often
    // is unnecessary and too long runs into browser warnings for long-lived timeouts
    // the actual attempts will be gated by `nextAttemptAt`
    const timeout = setInterval(() => {
      check();
    }, 15 * 1000);
    return () => {
      clearInterval(timeout);
    };
  }, [check]);

  return null;
};
