import { FrontDoorLayout } from "@layouts/FrontDoorLayout";

import { SwitchRole } from "./SwitchRole";

export function SwitchRoleAsPage() {
  return (
    <FrontDoorLayout
      heading="Switch Role"
      lowerLine="Be a teacher and a student – all in one account."
    >
      <SwitchRole />
    </FrontDoorLayout>
  );
}
