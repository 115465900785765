// DO NOT CHANGE THESE WILLY NILLY -- Emails, Partners, Client, and Server all need to agree

/*
 * wjustice: Note these FortePromoIds only formally "exist" here.
 * If we want to use these more broadly we should save them as enums in the DB and gql.
 */
export enum KnownFortePromoIds {
  SWEETWATER_B3G1F = "sweetwater-b3g1f", // Sweetwater "Buy 3 get 1 free"
  SWEETWATER_FLF = "sweetwater-flf", // Sweetwater "first lesson free"
  TONESTRO_FLF = "tonestro-flf", // Tonestro "first lesson free"
  YOUSICIAN_FLF = "yousician-flf", // Yousician "first lesson free"
}

export enum KnownUtmQueryParams {
  utm_campaign = "utm_campaign",
  utm_content = "utm_content",
  utm_medium = "utm_medium",
  utm_source = "utm_source",
  utm_term = "utm_term",
}
