import { type PropsWithChildren } from "react";

import { type FlowbiteSizes } from "flowbite-react";
import { twMerge } from "tailwind-merge";

type TextProps = {
  className?: string;
  secondary?: boolean;
  size?: keyof FlowbiteSizes;
};

export function StyledText({
  children,
  className = "",
  secondary = false,
  size = "md",
}: TextProps & PropsWithChildren) {
  const colorClassNames = secondary
    ? "text-gray-600 dark:text-gray-400"
    : "text-gray-900 dark:text-white";
  return (
    <p className={twMerge(`text-${size}`, colorClassNames, className)}>
      {children}
    </p>
  );
}
