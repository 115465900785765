import { useState } from "react";

import { RoleTypeEnum } from "constants/role";

import { Banner } from "@riffs/Banner";
import { directlySetWindowLocation } from "@util/directlySetWindowLocation";

import { INDEX } from "../helpers/routes";
import { useMaybeViewerContext } from "./MaybeViewerProvider";

const IMPERSONATION_LOCAL_STORAGE_KEY = "debugViewerId";

/**
 * The hook for working with Impersonation
 */
export const useImpersonation = () => {
  const { setAutoRoleRedirect, updateCurrentRole } = useMaybeViewerContext();
  const [impersonatingUserId, setImpersonatingUserId] = useState(
    typeof localStorage !== "undefined" &&
      localStorage.getItem(IMPERSONATION_LOCAL_STORAGE_KEY),
  );

  /**
   * For checking if localStorage was set, so we can do other things with this information
   */
  const localStorageIsSet = () => {
    return (
      typeof localStorage !== "undefined" &&
      Boolean(localStorage.getItem(IMPERSONATION_LOCAL_STORAGE_KEY))
    );
  };

  /**
   * For resetting Impersonation in a consistent way in other places
   */
  const clearImpersonation = async () => {
    setImpersonatingUserId(null);

    setAutoRoleRedirect(false);

    if (localStorageIsSet()) {
      localStorage.removeItem(IMPERSONATION_LOCAL_STORAGE_KEY); // Clear localStorage entry
    }

    updateCurrentRole({ type: RoleTypeEnum.FORTE_ADMIN });
  };

  /**
   * To specify a user to impersonate
   */
  const impersonate = async (newUserId: string) => {
    if (newUserId) {
      localStorage.setItem(IMPERSONATION_LOCAL_STORAGE_KEY, newUserId);
    }

    // await apolloClient.resetStore(); // unnecessary since we're purposefully doing a full page reload to avoid cache / timing issues
    setImpersonatingUserId(newUserId);

    // eslint-disable-next-line no-console
    console.debug(
      `Impersonating user ${newUserId}, redirecting to SWITCH_ROLE`,
    );

    directlySetWindowLocation(INDEX);
  };

  return {
    /**
     * Function to impersonate a user
     */
    impersonate,
    impersonatingUserId,
    /**
     * Whether we are currently impersonating a user or not
     */
    impersonating: Boolean(impersonatingUserId),
    /**
     * Clear impersonation from localStorage and state
     */
    clearImpersonation,
  };
};

/**
 * Banner to show at the top when impersonating a user
 */
export const MaybeImpersonationBanner = () => {
  const { viewer } = useMaybeViewerContext();

  const { clearImpersonation, impersonating, impersonatingUserId } =
    useImpersonation();

  if (!impersonating) {
    return null;
  }

  if (impersonatingUserId !== viewer?.id) {
    return (
      <Banner color="failure" onDismiss={() => clearImpersonation()}>
        Impersonating <b>{impersonatingUserId}</b> failed. Please dismiss this
        alert and try again.
      </Banner>
    );
  }

  return (
    <Banner onDismiss={() => clearImpersonation()}>
      Impersonating <b>{viewer?.givenNameAndInitialOfFamilyName}</b>
    </Banner>
  );
};
