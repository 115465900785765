import clsx from "clsx";
import { AvatarProps, Avatar as FlowbiteAvatar } from "flowbite-react";

import { Logo } from "./Logo";

import { useUserAvatarQuery } from "@graphql";

/**
 * Shows a Silhoutte avatar if `img` is empty
 */
export const Avatar = ({ img, rounded = true, ...props }: AvatarProps) => {
  return (
    <FlowbiteAvatar
      img={img || "/profile-picture-placeholder.jpg"}
      rounded={rounded}
      {...props}
    ></FlowbiteAvatar>
  );
};

export enum AvatarForUserDefaultAvatar {
  FORTE_LOGO,
  SILHOUETTE,
}

export const AvatarForUser = ({
  userId,
  rounded = true,
  defaultAvatar = AvatarForUserDefaultAvatar.SILHOUETTE,
  ...props
}: Omit<AvatarProps, "img"> & {
  userId: undefined | null | string;
  defaultAvatar?: AvatarForUserDefaultAvatar;
}) => {
  const { data } = useUserAvatarQuery({
    skip: !userId,
    fetchPolicy: "cache-first",
    variables: {
      userId: userId as string,
    },
  });

  const avatarUrl = data?.user?.currentAvatar?.url;
  if (!avatarUrl && defaultAvatar === AvatarForUserDefaultAvatar.FORTE_LOGO) {
    return (
      <div className="flex justify-center items-center space-x-4 rounded">
        <Logo className={clsx("h-36 w-36 fill-white")} />
      </div>
    );
  }

  // defaults to the Silhoutte
  return <Avatar img={avatarUrl} rounded={rounded} {...props} />;
};

Avatar.Group = FlowbiteAvatar.Group;
Avatar.Counter = FlowbiteAvatar.Counter;
