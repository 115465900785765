import { SignInResponse } from "next-auth/react";

/**
 * Sets `window.location.href` to force an immediate redirect.
 */
export function directlySetWindowLocation(path: string) {
  console.debug("directlySetWindowLocation: ", path);

  // Check that window is defined to ensure this executes only clientside.
  if (typeof window !== "undefined") {
    window.location.href = path;
  }
}

/**
 * Manually handling the redirect since `redirect: false` in the options passed to `signIn()`.
 */
export function redirectToSignInDestination(
  signInResult: SignInResponse | undefined,
) {
  const destination = signInResult?.url;

  if (destination) {
    directlySetWindowLocation(destination);
  }
}
