/**
 * LayoutData is a context and hook for working with data that needs to be passed into layouts
 * */
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

const LayoutData = createContext<{
  /** InfoBlock `heading` in FrontDoorLayout */
  infoBlockHeading: string | undefined;
  /** InfoBlock `heading` in FrontDoorLayout */
  setInfoBlockHeading: Dispatch<SetStateAction<string | undefined>>;
  /** InfoBlock `lowerLine` in FrontDoorLayout */
  infoBlockLowerLine: string | undefined;
  /** InfoBlock `lowerLine` in FrontDoorLayout */
  setInfoBlockLowerLine: Dispatch<SetStateAction<string | undefined>>;
}>({
  infoBlockHeading: "",
  infoBlockLowerLine: "",
  setInfoBlockHeading: () => {},
  setInfoBlockLowerLine: () => {},
});

export const LayoutDataProvider = ({ children }: PropsWithChildren) => {
  const [infoBlockHeading, setInfoBlockHeading] = useState<
    string | undefined
  >();
  const [infoBlockLowerLine, setInfoBlockLowerLine] = useState<
    string | undefined
  >();

  return (
    <LayoutData.Provider
      value={{
        infoBlockHeading,
        setInfoBlockHeading,
        infoBlockLowerLine,
        setInfoBlockLowerLine,
      }}
    >
      {children}
    </LayoutData.Provider>
  );
};

/** Hook for using/setting LayoutData */
export const useLayoutData = () => useContext(LayoutData);
