import { useState } from "react";

import { Alert, Button } from "flowbite-react";

import { Modal } from "@riffs/Modal";

export type PopCancelConfirmationModalT = (arg: {
  cancelText?: CancelConfirmationModalPropsT["cancelText"];
  confirmText?: CancelConfirmationModalPropsT["confirmText"];
  content: CancelConfirmationModalPropsT["content"];
  onCancel?: () => void;
  onConfirm: () => void;
  title?: CancelConfirmationModalPropsT["title"];
}) => void;

export function useCancelConfirmationModal() {
  const [modalProps, setModalProps] =
    useState<CancelConfirmationModalPropsT | null>(null);

  const popCancelConfirmationModal: PopCancelConfirmationModalT = ({
    cancelText,
    confirmText,
    content,
    onCancel,
    onConfirm,
    title,
  }) => {
    setModalProps({
      content,
      showModal: true,
      title: title || "Confirm update",
      confirmText: confirmText || "Confirm",
      cancelText: cancelText || "Cancel",
      onConfirm: () => {
        onConfirm();
        setModalProps(null);
      },
      onCancel: () => {
        if (onCancel) {
          onCancel();
        }

        setModalProps(null);
      },
    });
  };

  return {
    popCancelConfirmationModal,
    cancelConfirmationModalProps: modalProps,
    CancelConfirmationModal,
  };
}

type CancelConfirmationModalPropsT = {
  cancelText?: string;
  confirmText?: string;
  content: () => JSX.Element;
  onCancel?: () => void;
  onConfirm: () => void;
  showModal: boolean;
  title?: string;
};

function CancelConfirmationModal({
  cancelText,
  confirmText,
  content,
  onCancel,
  onConfirm,
  showModal,
  title,
}: CancelConfirmationModalPropsT) {
  return (
    <>
      {showModal && (
        <Modal onClose={onCancel}>
          <Modal.Header onClose={onCancel}>{title}</Modal.Header>
          <Modal.Body>
            <div className="pb-8 text-center">
              <Alert
                color="warning"
                withBorderAccent
                className="inline-block mt-5 p-8 max-w-sm text-left"
              >
                <div className="flex flex-col items-center">
                  <h2 className="font-bold text-lg mb-4 mr-auto">
                    ATTENTION! This action cannot be undone!
                  </h2>
                  {content()}
                  <div className="mr-auto">
                    <Button
                      color="warning"
                      className="my-4 !bg-transparent border border-yellow-700 hover:border-yellow-400 text-yellow-700"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.preventDefault();
                        if (onCancel) {
                          onCancel();
                        }
                      }}
                    >
                      {cancelText}
                    </Button>
                    <Button color="warning" onClick={onConfirm}>
                      {confirmText}
                    </Button>
                  </div>
                </div>
              </Alert>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
