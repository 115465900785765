import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";

import * as CryptoJS from "crypto-js";
import { useRouter } from "next/router";
import { useLocalStorage } from "usehooks-ts";

import { directlySetWindowLocation } from "@util/directlySetWindowLocation";

import { NEXT_PUBLIC_VERCEL_ENV } from "../constants/config";
import { BASE_URL, INDEX, SETTINGS } from "../helpers/routes";
import { useAuthedViewer } from "./AuthedViewerProvider";

export enum FeatureFlagOptions {
  SCHEDULING = "SCHEDULING",
  INSTITUTIONS = "INSTITUTIONS",
  BILLING = "BILLING",
}

type FeatureFlagContextT = {
  userCanAccess: (flagToCheck: FeatureFlagOptions) => boolean;
};

const FeatureFlagContext = createContext<FeatureFlagContextT>({
  userCanAccess: () => false,
});

const SOME_SECRET = "Congrats on finding this secret. You are so smart.";

export const useFeatureFlags = () => useContext(FeatureFlagContext);

export const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
  const { viewer } = useAuthedViewer();

  const [storedFlags, setStoredFlags] = useLocalStorage("ff", "");

  const { query } = useRouter();

  const enabledFlags = useMemo(() => {
    const { enabledFlags, userId: decodedUserId } = decode(storedFlags);
    if (viewer.id === decodedUserId && enabledFlags && enabledFlags.length) {
      return enabledFlags;
    } else {
      return [];
    }
  }, [viewer, storedFlags]);

  useEffect(() => {
    if (viewer.id && "ff" in query) {
      const queryPrefix = "?ff=";
      /*
        Using router.query was doing some odd string parsing so instead
        we are getting params directly using window.location.search but
        still using router.query to trigger the useEffect.
      */
      setStoredFlags(window.location.search.replace(queryPrefix, ""));
      directlySetWindowLocation(INDEX);
    }
  }, [query, viewer, setStoredFlags]);

  const userCanAccess = (flagToCheck: FeatureFlagOptions) => {
    if (
      process.env.NODE_ENV === "development" ||
      NEXT_PUBLIC_VERCEL_ENV === "development" ||
      NEXT_PUBLIC_VERCEL_ENV === "preview"
    ) {
      return true;
    }
    return enabledFlags.some((flag) => flag === flagToCheck);
  };

  const contextValue = {
    userCanAccess,
  };

  return (
    <FeatureFlagContext.Provider value={contextValue}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

type DecodeReturnT = {
  userId: string;
  enabledFlags: Array<FeatureFlagOptions>;
};

function decode(localStorageValue: string): DecodeReturnT {
  const bytes = CryptoJS.AES.decrypt(localStorageValue, SOME_SECRET);
  const [flagVals, userId] = bytes.toString(CryptoJS.enc.Utf8).split("/");

  return {
    userId,
    enabledFlags: flagVals.split(",") as Array<FeatureFlagOptions>,
  };
}

export function getFeatureFlagUrl(
  userId: string,
  selectedFeatureFlags: FeatureFlagOptions[],
) {
  const url = `${BASE_URL}${SETTINGS}?ff=${generateEncodedString(
    selectedFeatureFlags,
    userId,
  )}`;
  return url;
}

function generateEncodedString(
  toEncode: Array<FeatureFlagOptions>,
  userId: string,
): string {
  const str = `${toEncode.join(",")}/${userId}`;
  return CryptoJS.AES.encrypt(str, SOME_SECRET).toString();
}
