import { FormEvent, useCallback, useEffect, useRef, useState } from "react";

import { Media, Message } from "@twilio/conversations";
import { DateTime } from "luxon";

import { AppChat } from "@components/Chat/AppChat/AppChat";
import { ILEChat } from "@components/Chat/ILEChat/ILEChat";
import { useMaybeViewerContext } from "@components/MaybeViewerProvider";
import { Icon } from "@riffs/Icon";
import {
  conversationsDate,
  downloadMediafile,
  getBlobFile,
  getMessages,
  prepareMessage,
} from "@util/twilioChatHelper";

import { useConversationContext } from "./ConversationContext";

export const FileToSend: React.FC<{
  file: File;
  onRemove: () => void;
}> = ({ file, onRemove }) => {
  return (
    <div className="inline-block max-w-[16rem]">
      <div className="flex items-center m-1 bg-indigo-400 rounded text-forteCharcoal-100">
        <span className="p-1 whitespace-pre-wrap leading-tight break-all">
          <Icon.Page />
        </span>
        <span className="p-1 leading-tight break-all whitespace-nowrap overflow-hidden truncate text-xs">
          {file.name}
        </span>
        <span
          className="p-1 hover:cursor-pointer hover:text-forteCharcoal-60"
          onClick={onRemove}
        >
          <Icon.Cancel />
        </span>
      </div>
    </div>
  );
};

export const Chat = (
  props: {
    onClose: () => void;
    className?: string;
  } & (
    | {
        isILE: true;
        isChatPanelOpen: boolean;
      }
    | {
        isILE: false;
        conversationTitle: string;
        forceFullscreenChat: boolean;
        toggleForceFullscreenChat: () => void;
      }
  ),
) => {
  const { isILE, className } = props;
  const conversationContext = useConversationContext();
  const twilioConversation = conversationContext?.twilioConversation;
  const { viewer } = useMaybeViewerContext();

  let previousDate: null | DateTime = null;
  const [filesToSend, setFilesToSend] = useState<File[]>([]);
  const chatScroll: React.MutableRefObject<HTMLDivElement | null> =
    useRef(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [messageList, setMessageList] = useState<Message[]>([]);
  const [downloadingFileIds, setDownloadingFileIds] = useState<string[]>([]);
  const [isLoadPreviousMessages, setLoadPreviousMessages] =
    useState<boolean>(false);
  const [uploadingMessageIndexes, setUploadingMessageIndexes] = useState<
    number[]
  >([]);

  useEffect(() => {
    if (twilioConversation) {
      fetchConversationMessage();
      twilioConversation.on("messageAdded", messageAdded);
    }

    return () => {
      if (twilioConversation) {
        conversationContext?.setUnreadMessageCount(0);
        setAllMessagesRead();
        twilioConversation.off("messageAdded", messageAdded);
      }
    };
  }, [twilioConversation]);

  useEffect(() => {
    if (messageList && messageList.length) {
      if (!isLoadPreviousMessages) {
        scrollToBottom();
      } else {
        if (chatScroll.current) {
          chatScroll.current?.scrollTo({
            top: 20,
            behavior: "smooth",
          });
        }
        setLoadPreviousMessages(false);
      }
    }
  }, [messageList]);

  useEffect(() => {
    if (uploadingMessageIndexes.length) {
      scrollToBottom();
    }
  }, [uploadingMessageIndexes]);

  const fetchConversationMessage = async () => {
    if (!twilioConversation) {
      return;
    }

    const unreadMessageCount =
      await twilioConversation.getUnreadMessagesCount();
    conversationContext?.setUnreadMessageCount(
      unreadMessageCount !== null
        ? unreadMessageCount
        : twilioConversation.lastMessage
        ? 1
        : 0,
    );

    const newMessages = await getMessages(twilioConversation, 30, 0);
    setLoading(false);
    setMessageList(newMessages.items);
  };

  const messageAdded = useCallback(
    (message: Message) => {
      if (!twilioConversation) {
        return;
      }

      if (viewer && message.author !== viewer?.id) {
        conversationContext?.setUnreadMessageCount((count) => count + 1);
      } else {
        conversationContext?.setUnreadMessageCount(0);
      }
      setMessageList((oldMessages) => [...oldMessages, message]);
    },
    [viewer, twilioConversation],
  );

  const setAllMessagesRead = async () => {
    if (twilioConversation) {
      try {
        await twilioConversation.setAllMessagesRead();
      } catch (error) {
        console.error("setAllMessagesRead error", error);
      }
    }
  };

  const scrollToBottom = () => {
    chatScroll.current?.scrollTo(0, chatScroll.current.scrollHeight);
  };

  const shareFile = async (files: FileList) => {
    const fileList: File[] = [...filesToSend];

    for (let i = 0; i < files.length; i++) {
      fileList.push(files[i]);
    }
    setFilesToSend(fileList);
    scrollToBottom();
  };

  const loadPreviousMessage = async (e: any) => {
    const element = e.target;

    if (element.scrollTop === 0 && twilioConversation) {
      if (twilioConversation && messageList?.length) {
        const totalMessageCount = await twilioConversation.getMessagesCount();
        if (messageList?.length >= totalMessageCount) {
          return;
        }
        setLoadPreviousMessages(true);
        const message = await getMessages(
          twilioConversation,
          10,
          messageList.length + 1,
        );
        setMessageList((oldMessages) => [
          ...(oldMessages || []),
          ...message.items,
        ]);
      }
    }
  };

  const maybeRenderDate = (date: Date | null) => {
    if (!date) {
      return null;
    }
    const dateTime = DateTime.fromJSDate(date);
    if (previousDate && previousDate.ordinal === dateTime.ordinal) {
      return null;
    }
    previousDate = dateTime;
    const dateLabel = conversationsDate(dateTime);

    return (
      <div className="flex justify-center my-3">
        <span className="bg-gray-400 px-3 py-1 rounded-full text-xs">
          {dateLabel}
        </span>
      </div>
    );
  };

  const downloadHandler = async (media: Media) => {
    if (!media) {
      return;
    }
    setDownloadingFileIds([...downloadingFileIds, media.sid]);
    try {
      const blob = await getBlobFile(media);
      if (blob) {
        downloadfile(blob, media.filename, media.sid);
      }
    } catch (error) {
      setDownloadingFileIds(
        downloadingFileIds.filter((id) => id !== media.sid),
      );
      console.error("downloadHandler error", error);
    }
  };

  const downloadfile = (blob: Blob, filename: string | null, sid: string) => {
    downloadMediafile(blob, filename);
    setDownloadingFileIds(downloadingFileIds.filter((id) => id !== sid));
  };

  const handleSendMessage = async (event: FormEvent | KeyboardEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (
      !twilioConversation ||
      (message.length === 0 && filesToSend.length === 0) ||
      !viewer
    ) {
      return;
    }

    try {
      if (filesToSend.length > 0) {
        setUploadingMessageIndexes((prevIndexes) => [...prevIndexes, 1]);
      }

      const messageBuilder = prepareMessage(
        twilioConversation,
        message,
        filesToSend,
      );
      messageBuilder.setAttributes({
        sentBy: viewer.id,
      });
      setMessage("");
      setFilesToSend([]);

      await messageBuilder.build().send();
      await setAllMessagesRead();

      setUploadingMessageIndexes(
        uploadingMessageIndexes.slice(0, uploadingMessageIndexes.length - 1),
      );
      scrollToBottom();
    } catch (error) {
      console.error("handleSendMessage error: ", error);
    }
  };

  const onClose = () => {
    setAllMessagesRead();
    props.onClose();
  };

  return isILE ? (
    <ILEChat
      chatScroll={chatScroll}
      downloadHandler={downloadHandler}
      downloadingFileIds={downloadingFileIds}
      filesToSend={filesToSend}
      handleSendMessage={handleSendMessage}
      loadPreviousMessage={loadPreviousMessage}
      message={message}
      messageList={messageList}
      loading={loading}
      maybeRenderDate={maybeRenderDate}
      setFilesToSend={setFilesToSend}
      setMessage={setMessage}
      shareFile={shareFile}
      uploadingMessageIndexes={uploadingMessageIndexes}
      className={className}
      scrollToBottom={scrollToBottom}
      isChatPanelOpen={props.isChatPanelOpen}
      onClose={onClose}
    />
  ) : (
    <AppChat
      chatScroll={chatScroll}
      downloadHandler={downloadHandler}
      downloadingFileIds={downloadingFileIds}
      filesToSend={filesToSend}
      handleSendMessage={handleSendMessage}
      loadPreviousMessage={loadPreviousMessage}
      message={message}
      messageList={messageList}
      loading={loading}
      maybeRenderDate={maybeRenderDate}
      setFilesToSend={setFilesToSend}
      setMessage={setMessage}
      shareFile={shareFile}
      uploadingMessageIndexes={uploadingMessageIndexes}
      className={className}
      conversationTitle={props.conversationTitle}
      onClose={onClose}
    />
  );
};
