import { FaXmark } from "react-icons/fa6";

import Image from "next/image";
import { twMerge } from "tailwind-merge";

import { useOnboardingQueryParamCaptureContext } from "@components/OnboardingQueryParamCaptureProvider";
import { Logo } from "@riffs/Logo";

import { useMaybeViewerContext } from "./MaybeViewerProvider";

export enum KnownDemandsidePartners {
  skoove = "skoove",
  sweetwater = "sweetwater",
  tonestro = "tonestro",
  yousician = "yousician",
}

export const DemandsidePartnerLogoLookup = {
  [KnownDemandsidePartners.tonestro]: () => (
    <Image
      alt="Tonestro logo"
      src="/logos/tonestro_logo_white.svg"
      width={120}
      height={40}
    />
  ),
  [KnownDemandsidePartners.sweetwater]: () => (
    <Image
      alt="Sweetwater logo"
      className="translate-y-1"
      src="/logos/sweetwater_logo_white.svg"
      width={150}
      height={40}
    />
  ),
  [KnownDemandsidePartners.skoove]: () => (
    <Image
      alt="Skoove logo"
      src="/logos/skoove_logo_white.svg"
      width={140}
      height={40}
    />
  ),
  [KnownDemandsidePartners.yousician]: () => (
    <Image
      alt="Yousician logo"
      src="/logos/yousician_logo_white.svg"
      width={150}
      height={40}
    />
  ),
};

const ParnterSpecificStyles = {
  [KnownDemandsidePartners.skoove]: "flex-row-reverse",
  [KnownDemandsidePartners.sweetwater]: "",
  [KnownDemandsidePartners.tonestro]: "",
  [KnownDemandsidePartners.yousician]: "",
};

export const LogoWithCoBranding = () => {
  const { onboardingMetadata } = useOnboardingQueryParamCaptureContext();
  const maybeViewerContext = useMaybeViewerContext();

  const { referringPartnerId, utmParams } = onboardingMetadata;

  const incomingPartnerId =
    // Users only have one associatedPartnerId (for now) so this is ok in the near term
    maybeViewerContext?.viewer?.associatedPartnerIds[0] ||
    referringPartnerId ||
    utmParams.utm_source;

  const demandsidePartnerId =
    incomingPartnerId && incomingPartnerId in KnownDemandsidePartners
      ? (incomingPartnerId as KnownDemandsidePartners)
      : null;

  let PartnerLogo = null;

  if (demandsidePartnerId) {
    PartnerLogo = DemandsidePartnerLogoLookup[demandsidePartnerId];
  }

  return (
    <div
      className={twMerge(
        "flex items-center justify-center gap-3",
        demandsidePartnerId && ParnterSpecificStyles[demandsidePartnerId],
      )}
    >
      <div className="w-28">
        <Logo />
      </div>

      {PartnerLogo && (
        <>
          <FaXmark className="text-gray-400" /> <PartnerLogo />
        </>
      )}
    </div>
  );
};
