// Using a separate file for constants because `routes.ts` pulls from here and gets used in the backend. The build would break if `routes.ts` imported from a file that uses `@` aliases in imports.
import { ViewerContextQuery } from "@graphql";

export enum RoleTypeEnum {
  STUDENT_OR_PARENT = "studentOrParent",
  TEACHER = "teacher",
  FORTE_ADMIN = "forteAdmin",
}

export type StudentOrParentRoleT = {
  type: RoleTypeEnum.STUDENT_OR_PARENT;
  organizationName: string;
  organizationId: string;
  organizationStillRequiresVerifiedAdult: boolean;
  organization: NonNullable<
    ViewerContextQuery["viewer"]
  >["organizations"][number];
  isAdmin: boolean;
  viewerNumTeachingRelationshipsAsLearner: number;
};

export type RoleT =
  | StudentOrParentRoleT
  | {
      type: RoleTypeEnum.TEACHER;
    }
  | {
      type: RoleTypeEnum.FORTE_ADMIN;
    };
