import React, {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { type RoleT, RoleTypeEnum } from "constants/role";
import some from "lodash/some";

import {
  type ActiveConversation,
  getTwilioIdentityUserId,
} from "@components/Chat/ConversationContext";
import { useMaybeViewerContext } from "@components/MaybeViewerProvider";
import { useConversationId } from "@components/TeachingRelationshipConversationId";

import {
  type DisambiguatingTeachingRelationshipFragment,
  useConversationDetailsForChatLazyQuery,
} from "@graphql";

type ActiveConversationT = ActiveConversation & { conversationTitle: string };

type ChatContextT = {
  activeConversation: ActiveConversationT | null;
  closeActiveConversation: () => void;
  handleUnreadCountUpdate: (
    teachingRelationshipId: string,
    unreadCount: number,
  ) => void;
  isChatDrawerOpen: boolean;
  openChatList: () => void;
  openChatWithId: (chatId: string) => void;
  setActiveConversation: (converation: ActiveConversationT) => void;
  setIsChatDrawerOpen: (value: boolean) => void;
  shouldShowUnreadIndicator: boolean;
};

const ChatContext = createContext<ChatContextT | undefined>(undefined);

const getSimpleConversationTitle = (
  teachingRelationship: DisambiguatingTeachingRelationshipFragment,
  currentRole: RoleT,
) => {
  return currentRole?.type === RoleTypeEnum.STUDENT_OR_PARENT
    ? `${teachingRelationship.teacher.givenNameAndInitialOfFamilyName} (${teachingRelationship.instrument.name})`
    : `${teachingRelationship.learner.givenNameAndInitialOfFamilyName} (${teachingRelationship.instrument.name})`;
};

type UnreadCountLookupT = {
  [teachingRelationshipId: string]: number;
};

export const AppChatProvider = ({ children }: PropsWithChildren) => {
  const [unreadCountLookup, setUnreadCountLookup] =
    useState<UnreadCountLookupT | null>();
  const [isChatDrawerOpen, setIsChatDrawerOpen] = useState(false);
  const [activeConversation, setActiveConversation] =
    useState<ActiveConversationT | null>(null);

  const { getOrCreateConversationId } = useConversationId();

  const [getConversationDetailsForChat] =
    useConversationDetailsForChatLazyQuery();

  const { currentRole, viewer } = useMaybeViewerContext();

  const openChatWithId = useCallback(
    async (teachingRelationshipId: string) => {
      setIsChatDrawerOpen(true);
      await getOrCreateConversationId(teachingRelationshipId);

      const { data: conversationDetails } = await getConversationDetailsForChat(
        {
          variables: {
            id: teachingRelationshipId,
          },
        },
      );

      if (!conversationDetails || !conversationDetails.viewer || !currentRole) {
        return;
      }

      const twilioIdentityUserId = getTwilioIdentityUserId(
        conversationDetails.viewer.id,
        conversationDetails.teachingRelationship,
      );

      setActiveConversation({
        conversationId: teachingRelationshipId,
        twilioIdentityUserId,
        conversationTitle: getSimpleConversationTitle(
          conversationDetails.teachingRelationship,
          currentRole,
        ),
      });
    },
    [currentRole, getConversationDetailsForChat, getOrCreateConversationId],
  );

  useEffect(() => {
    const QUERY_PARAM_CHAT_KEY = "chatId";
    const url = new URL(window.location.href);
    if (!viewer) {
      return;
    }

    const chatId = url.searchParams.get(QUERY_PARAM_CHAT_KEY);
    if (chatId) {
      openChatWithId(chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeActiveConversation = useCallback(() => {
    setActiveConversation(null);
  }, [setActiveConversation]);

  const handleUnreadCountUpdate = useCallback(
    (teachingRelationshipId: string, unreadCount: number) => {
      setUnreadCountLookup((previousLookup) => {
        const newLookup = {
          ...previousLookup,
          [teachingRelationshipId]: unreadCount,
        };

        return newLookup;
      });
    },
    [setUnreadCountLookup],
  );

  const shouldShowUnreadIndicator = useMemo(() => {
    if (!unreadCountLookup) {
      return false;
    }

    return some(
      Object.values(unreadCountLookup).map((unreadCount) => unreadCount > 0),
    );
  }, [unreadCountLookup]);

  const openChatList = useCallback(() => {
    setIsChatDrawerOpen(true);
    closeActiveConversation();
  }, [closeActiveConversation, setIsChatDrawerOpen]);

  const value = useMemo(() => {
    return {
      activeConversation,
      closeActiveConversation,
      handleUnreadCountUpdate,
      isChatDrawerOpen,
      openChatList,
      openChatWithId,
      setActiveConversation,
      setIsChatDrawerOpen,
      shouldShowUnreadIndicator,
    };
  }, [
    activeConversation,
    closeActiveConversation,
    handleUnreadCountUpdate,
    isChatDrawerOpen,
    openChatList,
    openChatWithId,
    setActiveConversation,
    setIsChatDrawerOpen,
    shouldShowUnreadIndicator,
  ]);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useAppChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useAppChat must be used within a ChatProvider");
  }

  return context;
};
