import React from "react";

import {
  type NameForUser_UserFragment,
  NameForUser_UserFragmentDoc,
  useNameForUserQuery,
} from "@graphql";
import { getApolloClient } from "@graphql/apollo";

/**
 * Async-hook renders the name for the given `userId`, wrapped in a <span>
 */
export const NameForUser: React.FC<{
  className?: string;
  userId: undefined | null | string;
}> = ({ className, userId }) => {
  const { data } = useNameForUserQuery({
    skip: !userId,
    fetchPolicy: "cache-first",
    variables: {
      userId: userId as string,
    },
  });

  return (
    <span className={className}>
      {data?.user.givenNameAndInitialOfFamilyName}
    </span>
  );
};

/**
 * Synchronously reads the given `userId` directly off of the Apollo Cache
 * Will return null if the ApolloCache wasn't already primed with the data you're requesting
 */
export function getNameForUser(userId: string): null | string {
  const user = getApolloClient().readFragment<NameForUser_UserFragment>({
    fragment: NameForUser_UserFragmentDoc,
    id: `User:${userId}`,
  });

  if (!user) {
    return null;
  }

  return user.givenNameAndInitialOfFamilyName;
}
