import { Dispatch, FormEvent, SetStateAction } from "react";
import { isChrome, isMobile, isSafari } from "react-device-detect";
import { FaPaperPlane, FaPaperclip } from "react-icons/fa6";
import TextareaAutosize from "react-textarea-autosize";

import { Media, Message } from "@twilio/conversations";
import clsx from "clsx";

import { FileToSend } from "@components/Chat/Chat";
import { ChatMessages } from "@components/Chat/ChatMessages";

export const AppChat = ({
  shareFile,
  chatScroll,
  loadPreviousMessage,
  maybeRenderDate,
  downloadingFileIds,
  downloadHandler,
  uploadingMessageIndexes,
  handleSendMessage,
  filesToSend,
  setFilesToSend,
  message,
  setMessage,
  messageList,
  loading,
}: {
  shareFile: (files: FileList) => Promise<void>;
  chatScroll: React.MutableRefObject<HTMLDivElement | null>;
  loadPreviousMessage: (e: any) => Promise<void>;
  maybeRenderDate: (date: Date | null) => JSX.Element | null;
  downloadingFileIds: string[];
  downloadHandler: (media: Media) => Promise<void>;
  uploadingMessageIndexes: number[];
  handleSendMessage: (event: FormEvent | KeyboardEvent) => Promise<void>;
  filesToSend: File[];
  setFilesToSend: Dispatch<SetStateAction<File[]>>;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  messageList: Message[];
  loading: boolean;
  onClose: () => void;
  className?: string;
  conversationTitle: string;
}) => {
  return (
    <>
      <div
        ref={chatScroll}
        className={clsx(
          "overflow-auto flex flex-col flex-grow",
          filesToSend.length > 0 ? "pb-12" : "pb-2",
        )}
        onScroll={loadPreviousMessage}
      >
        <ChatMessages
          isILE={false}
          loading={loading}
          messageList={messageList}
          maybeRenderDate={maybeRenderDate}
          downloadingFileIds={downloadingFileIds}
          downloadHandler={downloadHandler}
          uploadingMessageIndexes={uploadingMessageIndexes}
        />
      </div>

      <div
        className={clsx("px-2 py-1 border-t border-gray-700", {
          "mb-20": isMobile && isSafari,
          "mb-10": isMobile && isChrome,
        })}
      >
        <div className="overflow-auto max-h-32">
          {filesToSend.map((obj, i) => {
            return (
              <FileToSend
                key={i}
                file={obj}
                onRemove={() => {
                  setFilesToSend(filesToSend.filter((file) => file !== obj));
                }}
              />
            );
          })}
        </div>

        <TextareaAutosize
          id="chat"
          value={message}
          autoComplete="off"
          placeholder="Type your message"
          className="mt-1 resize-none block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 dark:text-gray-200 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm"
          minRows={1}
          maxRows={7}
          onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handleSendMessage(event);
            }
          }}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
        <div className="flex justify-end items-center text-gray-600 dark:text-gray-200">
          <label
            className="ltr:right-0 rtl:left-0 cursor-pointer px-2 py-4"
            htmlFor="button-file"
          >
            <input
              className="hidden"
              id="button-file"
              type="file"
              onChange={(event) => {
                if (event.target.files) {
                  shareFile(event.target.files);
                }
              }}
              multiple
            />

            <FaPaperclip />
          </label>

          <button className="p-4 px-2" onClick={handleSendMessage}>
            <FaPaperPlane />
          </button>
        </div>
      </div>
    </>
  );
};
