import React, { useState } from "react";

type Props = React.PropsWithChildren<{
  handleDrop: (files: FileList) => void;
}>;

const DragAndDrop: React.FC<Props> = (props) => {
  const [drag, setDrag] = useState(false);

  // This probably doesnt do what it was intended to do
  let dragCounter = 0;

  const handleDragIn = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter = 1;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  };

  const handleDragOut = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter -= 1;
    if (dragCounter === 0) {
      setDrag(false);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setDrag(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      props.handleDrop(event.dataTransfer.files);
      event.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  return (
    <div
      className="contents relative"
      onDragOver={handleDragIn}
      onDragLeave={handleDragOut}
      onDrop={handleDrop}
    >
      {drag && (
        <div className="bg-white bg-opacity-80 absolute top-0 bottom-0 left-0 right-0 z-50 border-gray-400 border-4 border-dashed">
          <div className="absolute top-1/2 right-0 left-0 text-center text-gray-500 text-4xl">
            <div>Drop Here :)</div>
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
};
export default DragAndDrop;
