import { useEffect } from "react";

import { useSession } from "next-auth/react";
import Script from "next/script";

import { NEXT_PUBLIC_VERCEL_ENV } from "../constants/config";
import { useMaybeViewerContext } from "./MaybeViewerProvider";

const enablePendo =
  NEXT_PUBLIC_VERCEL_ENV === "production" ||
  (NEXT_PUBLIC_VERCEL_ENV === "preview" &&
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "main");
let hasEmittedDebugMessage = false;

export const Pendo = () => {
  const { currentRole } = useMaybeViewerContext();

  const roleType = currentRole?.type;
  const { viewer } = useMaybeViewerContext();
  const { data: sessionData } = useSession();

  useEffect(() => {
    if (!enablePendo) {
      return;
    }

    if (typeof pendo === "undefined" || !pendo) {
      return;
    }

    if (!sessionData || "passwordResetSession" in sessionData) {
      return;
    }

    if (!viewer || !viewer.pendoMetadata) {
      return;
    }

    const { __typename, ...pendoMetadata } = viewer.pendoMetadata;

    // this should only happen if we've stabalized roles, so we dont end up with role omitted because it fired too soon
    pendo.initialize({
      visitor: {
        id: viewer.id, // Required if user is logged in, default creates anonymous ID
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        full_name: viewer.givenNameAndInitialOfFamilyName, // Recommended if using Pendo Feedback
        ...(roleType && {
          role: roleType, // Optional, only pass it in if it's present
        }),
        ...pendoMetadata,

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });

    // eslint-disable-next-line no-console
    console.debug(
      `Pendo User updated; id: ${viewer.id}; name: ${viewer.givenNameAndInitialOfFamilyName} ; role: ${roleType}`,
    );
  }, [roleType, sessionData, viewer]);

  if (!enablePendo) {
    if (!hasEmittedDebugMessage) {
      hasEmittedDebugMessage = true;

      // eslint-disable-next-line no-console
      console.debug("Disabling Pendo in development");
    }

    return null;
  }

  return (
    <Script
      id="pendo"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          (function(apiKey){
              (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
              v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                  o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                  y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                  z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
          })('b37c192e-dbb0-4b59-683b-08c50f14b48a');
        `,
      }}
    />
  );
};
