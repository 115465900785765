import React, { useState } from "react";

import { useMediaQuery, useTheme } from "@material-ui/core";
import { Modal } from "flowbite-react";

import { ExternalLink } from "@components/_shared/ExternalLink";

export class ErrorIsLearnerWhoLacksPermissionToAccessCommunicationsFeatures extends Error {}
export class ErrorIsTeacherOfLearnerWhoLacksPermissionToAccessCommunicationsFeatures extends Error {}

const renderError = (error: Error) => {
  if (
    error instanceof
    ErrorIsLearnerWhoLacksPermissionToAccessCommunicationsFeatures
  ) {
    return (
      <div>
        <p className="mb-3">
          Sorry, you’re not allowed to access the studio yet since we’re not
          sure that you have permission from your parent.
        </p>
        <p className="mb-3">
          Please ask your parent or guardian to continue setting up their
          account.
        </p>
      </div>
    );
  }

  if (
    error instanceof
    ErrorIsTeacherOfLearnerWhoLacksPermissionToAccessCommunicationsFeatures
  ) {
    return (
      <div>
        <p className="mb-3">
          Sorry, the student is not allowed to access your studio yet since
          we’re not sure that they have permission from their parent.
        </p>
        <p className="mb-3">
          The student should ask their parent or guardian to continue setting up
          their account.
        </p>
      </div>
    );
  }

  if (error.name === "AbortError") {
    /**
     * Although the user and operating system both granted access to the hardware device, and no hardware
     * issues occurred that would cause a NotReadableError DOMException, throw if some problem occurred
     * which prevented the device from being used.
     */
    return (
      <p>
        Your browser and operating system both have access to your devices, but
        some other problem occured which is preventing the device from being
        used.
      </p>
    );
  }

  if (error.name === "NotAllowedError") {
    /**
     *
     * Thrown if one or more of the requested source devices cannot be used at this time. This will happen
     * if the browsing context is insecure (that is, the page was loaded using HTTP rather than HTTPS). It
     * also happens if the user has specified that the current browsing instance is not permitted access to
     * the device, the user has denied access for the current session, or the user has denied all access to
     * user media devices globally. On browsers that support managing media permissions with Permissions
     * Policy, this error is returned if Permissions Policy is not configured to allow access to the input
     * source(s).
     *
     *
     * Note: Older versions of the specification used SecurityError for this instead; SecurityError has taken on
     * a new meaning.
     */
    if (error.message === "Permission denied by system") {
      // Chrome only
      return (
        <p className="">
          Your operating system has blocked Chrome from accessing the microphone
          or camera.
        </p>
      );
    } else {
      return (
        <p className="">
          Chrome needs permission to access your camera and microphone. Grant
          these permissions in the Chrome settings.
        </p>
      );
    }
  }

  if (error.name === "NotFoundError") {
    /**
     * Thrown if no media tracks of the type specified were found that satisfy the given constraints.
     */
    return (
      <p className="">
        External device error. Please try using your built-in system camera and
        microphone.
      </p>
    );
  }

  if (error.name === "NotReadableError") {
    /**
     * Thrown if, although the user granted permission to use the matching devices, a hardware error
     * occurred at the operating system, browser, or Web page level which prevented access to the device.
     */
    return (
      <p>
        The device has permission to be used, but a hardware error occurred at
        the operating system, browser, or Web page level is preventing access to
        the device.
      </p>
    );
  }

  if (error.name === "OverconstrainedError") {
    /**
     * Thrown if the specified constraints resulted in no candidate devices which met the criteria
     * requested. The error is an object of type OverconstrainedError, and has a constraint property
     * whose string value is the name of a constraint which was impossible to meet, and a message
     * property containing a human-readable string explaining the problem.
     *
     * Note: Because this error can occur even when the user has not yet granted permission to use the
     * underlying device, it can potentially be used as a fingerprinting surface.
     */

    return <p>Could not find a desired device.</p>;
  }

  if (error.name === "SecurityError") {
    /**
     * Thrown if user media support is disabled on the Document on which getUserMedia() was called.
     * The mechanism by which user media support is enabled and disabled is left up to the individual
     * user agent.
     */

    return <p>Your browser is blocking UserMedia support.</p>;
  }

  if (error.name === "TypeError") {
    /**
     * Thrown if the list of constraints specified is empty, or has all constraints set to false.
     * This can also happen if you try to call getUserMedia() in an insecure context, since
     * navigator.mediaDevices is undefined in an insecure context.
     */

    return <p>Configuration error.</p>;
  }

  return <p className="">Unknown error.</p>;
};

export const ILEErrorContainer = ({ error }: { error: Error }) => {
  return (
    <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
      {renderError(error)}

      <hr className="my-4" />

      <StandardTroubleshootingAdvice />

      <hr className="my-4" />

      <StillHavingProblems error={error} />
    </div>
  );
};

const StandardTroubleshootingAdvice = () => {
  return (
    <>
      <div className="font-bold mb-4">Troubleshooting</div>

      <p>
        Please try refreshing your browser and closing other tabs + apps. Make
        sure your browser is up to date.
      </p>

      <p className="mt-6">
        Ensure your device has permission:
        <br />
        <PermissionsGuideLink>
          Learn more about Permissions
        </PermissionsGuideLink>
      </p>

      <div className="mt-6">
        Clearing your browser data might also help:
        <br />
        <ClearBrowserDataLink>Clear Browser Data</ClearBrowserDataLink>
      </div>
    </>
  );
};

const StillHavingProblems = ({ error }: { error: Error }) => {
  const errorName = error.name ?? "Unknown Error";
  const errorMessage = error.message ?? "Unexpected Error";
  return (
    <>
      <div className="mt-6 text-sm">
        If problems persist, please contact us at <HelpEmailLink /> and include
        the following details in addition to any steps you have taken.
      </div>

      <div className="mt-6 text-sm">
        <div className="font-bold">Technical details</div>
        <div className="">
          <p>{errorName}</p>
          <p>{errorMessage}</p>
        </div>
      </div>
    </>
  );
};

const PermissionsGuideLink: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ExternalLink href="https://fortelessons.com/permissions-guide">
      {children}
    </ExternalLink>
  );
};

const ClearBrowserDataLink: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ExternalLink href="chrome://settings/clearBrowserData">
      {children}
    </ExternalLink>
  );
};

const HelpEmailLink = () => {
  return (
    <ExternalLink href="mailto:help@fortelessons.com">
      help@fortelessons.com
    </ExternalLink>
  );
};

export const ILEError: React.FC<{
  error: Error;
  redirect?: boolean;
  onClose?: () => void;
}> = ({ error, redirect = true, onClose }) => {
  const theme = useTheme();
  const [showPopup, setShowPopup] = useState(true);
  const mdDevice = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setShowPopup(false);
    onClose?.();

    if (redirect) {
      window.location.href = "/";
    }
  };

  return (
    <Modal
      onClose={handleClose}
      show={showPopup}
      size={mdDevice ? "md" : "lg"}
      popup={true}
      position="center"
    >
      <div className="bg-white shadow rounded-md">
        <Modal.Header />

        <Modal.Body>
          <div className="space-y-6">
            <ILEErrorContainer error={error} />
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
