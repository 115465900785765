/* eslint-disable require-unicode-regexp */
import { type ViewerContextType } from "../components/AuthedViewerProvider";

export const isValidUUID = (string_: string) => {
  // eslint-disable-next-line regexp/no-useless-assertions
  const regexExp = /^[\da-f]{8}(?:\b-[\da-f]{4}){3}\b-[\da-f]{12}$/i;
  return regexExp.test(string_);
};

export const getFileExtention = (urlString: string): string => {
  const uuidSepartorIndex = urlString.lastIndexOf("_");
  const suffixString = urlString.slice(uuidSepartorIndex + 1, urlString.length);
  const linkWithoutUniqueSuffix =
    uuidSepartorIndex !== -1 && isValidUUID(suffixString)
      ? urlString.slice(0, uuidSepartorIndex)
      : urlString;
  const linkComponents = linkWithoutUniqueSuffix.split("/");
  const originalFilenameWithExtension =
    linkComponents[linkComponents.length - 1];
  const splitComponents = originalFilenameWithExtension.split(".");
  const fileType = splitComponents[splitComponents.length - 1] || "";
  return fileType;
};

export const validatePassword = (password: string) => {
  const requiredLengthRegex = /^.{8,32}$/;
  if (!requiredLengthRegex.test(password)) {
    return "Password must be between 8 and 32 characters long.";
  }

  const mustContainLowercaseRegex = /^[^\n\ra-z\u2028\u2029]*[a-z].*$/;
  if (!mustContainLowercaseRegex.test(password)) {
    return "Password must include at least one lowercase character";
  }

  // const mustContainUppercaseRegex = /^.*[A-Z].*$/;
  // if (!mustContainUppercaseRegex.test(password)) {
  //   return "Password must include at least one uppercase character";
  // }

  const mustContainNumberRegex = /^[^\d\n\r\u2028\u2029]*\d.*$/;
  if (!mustContainNumberRegex.test(password)) {
    return "Password must include at least one number";
  }

  return true;
};

export const validateUsername = (
  username: string,
): [true, null] | [false, string] => {
  if (!(username.length >= 6 && username.length <= 24)) {
    return [false, "Usernames must be between 6 and 24 characters long"];
  }

  // eslint-disable-next-line no-useless-escape
  const validCharsOnlyRegex = /^[\w+.\-]+$/;
  if (!validCharsOnlyRegex.test(username)) {
    return [
      false,
      "Usernames can only contain letters, digits, and these special characters ._+-",
    ];
  }

  const mustStartWithALetterRegex = /^[a-z].+$/i;
  if (!mustStartWithALetterRegex.test(username)) {
    return [false, "Usernames must start with a letter"];
  }

  // eslint-disable-next-line no-useless-escape
  const containsConsecutiveSpecialCharsRegex = /[+._\-]{2,}/;
  if (containsConsecutiveSpecialCharsRegex.test(username)) {
    return [false, "Usernames may not contain consecutive special characters"];
  }

  return [true, null];
};

/**
 * You can use this at the end of an exhaustive switch/if/else to ensure that if a new possibility were added,
 * typescript will complain because of an unhandled case.
 * Emits a console.error but otherwise does not change runtime behavior
 */
export function typecheckExhaustiveNeverCase(input: never) {
  console.error("Unhandled case: ", input);
}

export function getCreditBalanceAmountForViewer(
  viewer: ViewerContextType["viewer"],
) {
  const organizationPaymentAccount = viewer.organizations[0].paymentAccount;

  if (
    !organizationPaymentAccount ||
    !organizationPaymentAccount.creditBalance
  ) {
    return null;
  }

  if (organizationPaymentAccount.creditBalance.amount === 0) {
    return organizationPaymentAccount.creditBalance.amount;
  }

  // A balance should always be negative.
  // A negative balance means the organization/Stripe Customer is "owed" money by Forte.
  // The amount is in cents.
  const balanceAmount = -1 * organizationPaymentAccount.creditBalance.amount;

  return balanceAmount;
}

export const CURRENCY_FORMAT = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
