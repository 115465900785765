import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

import merge from "lodash/merge";

import { componentsToUserTeacherOfFlagId } from "./Matching/matchingHelpers";

export type UpdateBookmarkDataT = (updateObj: Record<string, boolean>) => void;

export type BookmarkedTeacherContextValue = {
  isBookmarkedForId: (input: {
    studentId: string;
    teacherOfId: string;
  }) => boolean;
  setBookmarksByUserTeacherOfFlagId: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  updateBookmarkData: UpdateBookmarkDataT;
};

export const BookmarkedTeacherContext =
  createContext<BookmarkedTeacherContextValue | null>(null);

export function useBookmarkedTeacherContext(): BookmarkedTeacherContextValue {
  const context = useContext(BookmarkedTeacherContext);
  if (context === null) {
    throw new Error(
      "useBookmarkedTeacherContext() must be wrapped in a <BookmarkedTeacherProvider>",
    );
  }

  return context;
}

export const BookmarkedTeacherProvider = ({ children }: PropsWithChildren) => {
  const [bookmarksByUserTeacherOfFlagId, setBookmarksByUserTeacherOfFlagId] =
    useState<Record<string, boolean>>({});

  const value = useMemo(() => {
    function updateBookmarkData(updateObj: Record<string, boolean>) {
      setBookmarksByUserTeacherOfFlagId((prev) => merge({}, prev, updateObj));
    }

    function isBookmarkedForId({
      studentId,
      teacherOfId,
    }: {
      studentId: string;
      teacherOfId: string;
    }): boolean {
      const userTeacherOfFlagId = componentsToUserTeacherOfFlagId({
        teacherOfId,
        studentId,
      });

      const isBookmarked = bookmarksByUserTeacherOfFlagId[userTeacherOfFlagId];
      return isBookmarked;
    }

    return {
      isBookmarkedForId,
      setBookmarksByUserTeacherOfFlagId,
      updateBookmarkData,
    };
  }, [bookmarksByUserTeacherOfFlagId]);

  return (
    <BookmarkedTeacherContext.Provider value={value}>
      {children}
    </BookmarkedTeacherContext.Provider>
  );
};
