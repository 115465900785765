export const Logo = ({ className = "fill-white" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 140 37"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M38.5651 36.2533C36.7731 36.2533 34.6451 36.0293 32.5545 35.32C28.1491 33.8267 25.4985 30.728 24.5651 26.2107C23.8185 22.6267 24.3785 19.192 26.3945 16.0933C27.7385 14.0027 29.6798 12.584 31.9198 11.688C36.2505 9.97067 40.6931 9.74667 45.1731 11.0907C50.2878 12.6213 53.8718 17.2133 54.2451 22.552C54.6558 28.1893 51.4451 33.08 46.2185 35.0587C43.8291 35.9173 41.4398 36.2907 38.5651 36.2533ZM39.0878 17.6613C37.2585 17.6613 35.3918 18.408 34.2345 19.8267C32.8905 21.544 32.7411 23.9707 33.7118 25.8747C34.9438 28.264 37.8931 29.0853 40.4318 28.6373C43.6425 28.0773 45.8078 24.8293 44.7998 21.656C44.2771 19.976 42.8958 18.5947 41.2158 17.9973C40.5438 17.7733 39.7971 17.6613 39.0878 17.6613Z" />
      <path d="M139.776 24.1201C132.235 24.1201 124.731 24.1201 117.189 24.1201C117.264 24.3441 117.301 24.5308 117.376 24.6801C118.235 26.9201 119.84 28.3014 122.192 28.7494C124.096 29.1228 125.925 28.8614 127.643 27.9281C128.277 27.5921 128.837 27.1068 129.323 26.6214C129.397 26.5468 129.509 26.5094 129.584 26.5094C132.907 26.5094 136.267 26.5094 139.589 26.5094C139.664 26.5094 139.701 26.5094 139.776 26.5094C139.701 26.6588 139.664 26.8081 139.589 26.9574C138.581 29.3468 137.125 31.4001 135.072 32.9681C133.243 34.3494 131.227 35.2081 128.987 35.6934C127.157 36.1041 125.253 36.2534 123.387 36.2161C121.819 36.2161 120.213 36.0668 118.683 35.7681C116.368 35.2828 114.203 34.4614 112.336 33.0054C109.947 31.1761 108.453 28.7494 107.781 25.8374C107.109 22.9254 107.595 20.1254 108.901 17.4374C110.208 14.7494 112.336 12.8828 115.099 11.7254C116.592 11.0908 118.197 10.6801 119.803 10.4561C121.408 10.2321 123.051 10.1574 124.656 10.2321C127.381 10.3441 129.995 10.7548 132.496 11.9121C136.229 13.6668 138.544 16.5788 139.44 20.6108C139.701 21.6934 139.813 22.8134 139.776 23.9334C139.776 23.9708 139.776 24.0454 139.776 24.1201ZM129.36 20.8348C129.36 20.7974 129.36 20.7601 129.36 20.7601C129.36 20.7228 129.36 20.6854 129.36 20.6481C129.248 20.1254 129.061 19.6774 128.763 19.2294C128.165 18.3708 127.381 17.8108 126.448 17.4001C125.216 16.8401 123.909 16.7281 122.565 16.8401C121.52 16.9521 120.549 17.2134 119.653 17.7361C118.645 18.3334 117.899 19.1548 117.525 20.3121C117.488 20.4614 117.451 20.6481 117.413 20.8348C121.408 20.8348 125.365 20.8348 129.36 20.8348Z" />
      <path d="M77.7653 17.4372L80.192 10.1945C79.5947 10.1945 74.0694 9.93315 71.3814 10.2691C69.0294 10.5678 66.7147 11.0905 64.5493 12.0985C61.7867 13.3678 59.472 15.2345 57.9787 18.0345C57.1573 19.5278 56.5227 22.2158 56.4853 23.9332C56.448 27.8905 56.4853 31.8852 56.4853 35.8425C56.4853 36.2532 56.4853 36.2532 56.896 36.2532C59.7333 36.2532 62.5707 36.2532 65.408 36.2532C65.52 36.2532 65.5947 36.1785 65.5947 36.0665C65.5947 32.0345 65.5947 27.9652 65.5947 23.9332C65.5947 22.2905 66.192 20.9465 67.4614 19.9012C68.4694 19.0798 69.6267 18.5572 70.896 18.2212C73.0987 17.5865 75.3387 17.3998 77.5787 17.4372C77.616 17.4372 77.6907 17.4372 77.7653 17.4372Z" />
      <path d="M106.325 29.4962C105.131 29.7949 103.973 30.0189 102.816 30.0562C101.659 30.1309 100.501 30.0935 99.3439 29.8322C99.0452 29.7575 98.7839 29.6829 98.5225 29.5709C97.4772 29.1229 96.9545 28.3389 96.9545 27.1815C96.9545 24.0455 96.9545 20.8722 96.9545 17.7362C96.9545 17.4749 96.9545 17.4749 97.2159 17.4749C99.9785 17.4749 102.779 17.4749 105.541 17.5122L107.968 10.1949C107.931 10.1949 107.893 10.1949 107.856 10.1949C104.309 10.1949 100.725 10.1949 97.1785 10.1949C96.9545 10.1949 96.9172 10.1202 96.9172 9.93353C96.9172 7.58153 96.9172 4.48286 96.9172 2.13086L87.2479 3.99753C87.2479 5.75219 87.2479 8.21619 87.2479 9.97086C87.2479 10.1575 87.2105 10.2322 86.9865 10.2322C85.4559 10.2322 83.6265 10.2322 82.0959 10.2322L79.7065 17.5495C79.7812 17.5495 79.8559 17.5495 79.9305 17.5495C82.3199 17.5495 84.6719 17.5495 87.0612 17.5495C87.2105 17.5495 87.2852 17.5495 87.2852 17.7362C87.2479 21.5815 87.2479 25.4642 87.2479 29.3095C87.2479 29.6082 87.2479 29.9442 87.2852 30.2429C87.4719 31.5495 88.1065 32.6322 89.0772 33.5655C90.1225 34.5735 91.4292 35.1709 92.7732 35.5815C94.3412 36.0669 95.9465 36.2909 97.5519 36.4029C99.1199 36.5149 100.688 36.4775 102.219 36.3655C103.749 36.2162 105.317 35.9922 106.811 35.6562C107.259 35.5442 107.707 35.4322 108.155 35.3202C107.557 33.2669 106.96 31.3629 106.325 29.4962Z" />
      <path d="M18.7787 6.61054C19.824 6.1252 20.9067 6.01321 22.0267 6.12521C23.408 6.27454 24.752 6.49854 26.096 6.72254L27.888 1.23454C25.2 0.338536 22.3627 -0.0347983 19.5253 0.00253503C17.5467 0.00253503 15.568 0.151871 13.664 0.786537C10.7893 1.7572 8.88533 3.62387 8.21333 6.61054C7.98933 7.6932 7.98933 8.73854 8.02667 9.8212C8.02667 10.1199 7.952 10.1945 7.65333 10.1945C6.45867 10.1945 3.808 10.1945 2.38933 10.1945L0 17.3999C1.30667 17.3999 5.89867 17.3999 7.46667 17.3999C7.91467 17.3999 7.91467 17.3999 7.91467 17.8479C7.91467 23.3732 7.91467 34.7225 7.91467 36.2905H17.024C17.024 34.7225 17.024 23.3732 17.024 17.8479C17.024 17.4372 17.024 17.4372 17.472 17.4372C18.7413 17.4372 20.7947 17.4372 22.4747 17.4372L24.864 10.1945H17.136C17.136 9.8212 17.136 9.52254 17.136 9.22387C17.136 8.1412 17.7707 7.05854 18.7787 6.61054Z" />
    </svg>
  );
};
