import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from "react";

import { useNextQuery } from "@util/useNextQuery";

import {
  type KnownFortePromoIds,
  KnownUtmQueryParams,
} from "../constants/queryParams";

export enum OnboardingTestId {
  // Survey -> Browse -> Register
  UNAUTHED_BROWSING = "unauthed_browsing",

  // Survey -> Register -> Browse
  USER_REGISTER = "user_register",
}

export enum KnownUtmSources {
  TEACHER_LINK = "teacher_link",
}

/*
 * wjustice: Yes, these are formatted in all kinds of ways.
 * They were created at different times by different people.
 * But we need to support all of them because they're all out in the wild.
 */
export enum OnboardingQueryParams {
  CORRELATION_ID = "c_id",
  DISCOUNT_CODE = "discount_code",
  FORTE_PROMO_ID = "fpromo_id",
  INSTITUTION_FILTER = "institution_filter",
  INSTITUTION_IDS = "institutionIds",
  INSTRUMENT_ID = "instrumentId",
  MATCHING_REQUEST_ID = "matchingRequestId",
  ONBOARDING_TEST_ID = "onboardingTestId",
  ORIGIN_SLUG = "origin_slug",
  REFERRING_PARTNER_ID = "refid",
  SIGNUP_CODE = "signupCode",
}

export enum PersonalDetailsQueryParams {
  EMAIL = "email",
  FIRST_NAME = "first_name",
  // LAST_NAME = "last_name",
  // MOBILE_PHONE = "mobilephone",
}

const AllKnownQueryParams = [
  ...Object.values(KnownUtmQueryParams),
  ...Object.values(OnboardingQueryParams),
  ...Object.values(PersonalDetailsQueryParams),
] as Array<string>;

type OnboardingQueryParamCaptureT = {
  email: string | null;
  firstName: string | null;
  institutionFilter: string | null;
  onboardingMetadata: {
    arbitraryUnhandledQueryParams: Record<
      string,
      | string
      | number
      | boolean
      | readonly string[]
      | readonly number[]
      | readonly boolean[]
      | null
      | undefined
    >;
    correlationId: string | null;
    createLearnerOfFromMatchingRequestId: string | null;
    discountCodePresentAtSignUp: string | null;
    fortePromoId: KnownFortePromoIds | null;
    onboardingTestId: string | null;
    originMarketingPageSlug: string | null;
    referringPartnerId: string | null;
    utmParams: Record<KnownUtmQueryParams, string | null>;
  };
  signupCode: string | null;
};

const OnboardingQueryParamCaptureContext =
  createContext<OnboardingQueryParamCaptureT | null>(null);

export const useOnboardingQueryParamCaptureContext = () => {
  const context = useContext(OnboardingQueryParamCaptureContext);
  if (context === null) {
    throw new Error(
      "useOnboardingQueryParamCaptureContext() must be wrapped in an <OnboardingDetailsProvider>",
    );
  }

  return context;
};

export const OnboardingQueryParamCaptureProvider = ({
  children,
}: PropsWithChildren) => {
  const { queries } = useNextQuery();

  const arbitraryUnhandledQueryParams = useMemo(() => {
    const arbitraryQueryParams: OnboardingQueryParamCaptureT["onboardingMetadata"]["arbitraryUnhandledQueryParams"] =
      {};

    for (const [key, value] of Object.entries(queries)) {
      if (!AllKnownQueryParams.includes(key)) {
        arbitraryQueryParams[key] = value;
      }
    }

    return arbitraryQueryParams;
  }, [queries]);

  const utmParams: Record<KnownUtmQueryParams, string | null> = useMemo(() => {
    const startingUtmObject: Record<KnownUtmQueryParams, string | null> = {
      [KnownUtmQueryParams.utm_campaign]: null,
      [KnownUtmQueryParams.utm_content]: null,
      [KnownUtmQueryParams.utm_medium]: null,
      [KnownUtmQueryParams.utm_source]: null,
      [KnownUtmQueryParams.utm_term]: null,
    };

    for (const [key, value] of Object.entries(queries)) {
      if (key in KnownUtmQueryParams && typeof value === "string") {
        startingUtmObject[key as KnownUtmQueryParams] = value;
      }
    }

    return startingUtmObject;
  }, [queries]);

  const originMarketingPageSlug =
    OnboardingQueryParams.ORIGIN_SLUG in queries
      ? (queries[OnboardingQueryParams.ORIGIN_SLUG] as string)
      : null;

  const institutionFilter =
    OnboardingQueryParams.INSTITUTION_FILTER in queries
      ? (queries[OnboardingQueryParams.INSTITUTION_FILTER] as string)
      : null;

  const referringPartnerIdFromQueryParams =
    OnboardingQueryParams.REFERRING_PARTNER_ID in queries
      ? (queries[OnboardingQueryParams.REFERRING_PARTNER_ID] as string)
      : null;

  const fortePromoId =
    OnboardingQueryParams.FORTE_PROMO_ID in queries
      ? (queries[OnboardingQueryParams.FORTE_PROMO_ID] as KnownFortePromoIds)
      : null;

  const createLearnerOfFromMatchingRequestId =
    OnboardingQueryParams.MATCHING_REQUEST_ID in queries
      ? (queries[OnboardingQueryParams.MATCHING_REQUEST_ID] as string)
      : null;

  const discountCodePresentAtSignUp =
    OnboardingQueryParams.DISCOUNT_CODE in queries
      ? (queries[OnboardingQueryParams.DISCOUNT_CODE] as string)
      : null;

  const signupCode =
    OnboardingQueryParams.SIGNUP_CODE in queries
      ? (queries[OnboardingQueryParams.SIGNUP_CODE] as string)
      : null;

  const email =
    PersonalDetailsQueryParams.EMAIL in queries
      ? (queries[PersonalDetailsQueryParams.EMAIL] as string)
      : null;

  const firstName =
    PersonalDetailsQueryParams.FIRST_NAME in queries
      ? (queries[PersonalDetailsQueryParams.FIRST_NAME] as string)
      : null;

  const correlationId =
    OnboardingQueryParams.CORRELATION_ID in queries
      ? (queries[OnboardingQueryParams.CORRELATION_ID] as string)
      : null;

  const onboardingTestId =
    OnboardingQueryParams.ONBOARDING_TEST_ID in queries
      ? (queries[OnboardingQueryParams.ONBOARDING_TEST_ID] as string)
      : null;

  // This is a fallback strictly for Tonestro. They launched without refid. This can be removed whenever they release afix to their app.
  const referringPartnerId =
    referringPartnerIdFromQueryParams ||
    (utmParams.utm_source === "tonestro" ? "tonestro" : null);

  const providerValue: OnboardingQueryParamCaptureT = useMemo(() => {
    return {
      email,
      firstName,
      institutionFilter,
      onboardingMetadata: {
        correlationId,
        discountCodePresentAtSignUp,
        originMarketingPageSlug,
        onboardingTestId,
        referringPartnerId,
        utmParams,
        arbitraryUnhandledQueryParams,
        fortePromoId,
        createLearnerOfFromMatchingRequestId,
      },
      signupCode,
    };
  }, [
    arbitraryUnhandledQueryParams,
    correlationId,
    createLearnerOfFromMatchingRequestId,
    discountCodePresentAtSignUp,
    email,
    firstName,
    fortePromoId,
    institutionFilter,
    onboardingTestId,
    originMarketingPageSlug,
    referringPartnerId,
    signupCode,
    utmParams,
  ]);

  return (
    <OnboardingQueryParamCaptureContext.Provider value={providerValue}>
      {children}
    </OnboardingQueryParamCaptureContext.Provider>
  );
};
