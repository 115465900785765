import { useState } from "react";
import { useForm } from "react-hook-form";

import clsx from "clsx";
import { Button } from "flowbite-react";

import { Heading } from "@riffs/Heading";

import { InstrumentSelect } from "./InstrumentSelect";
import { useMaybeViewerContext } from "./MaybeViewerProvider";

import {
  AddNewTeachingInstrument_NewInstrumentMutation,
  TeacherEditInstrumentProfileDocument,
  useAddNewTeachingInstrument_ExistingInstrumentsQuery,
  useAddNewTeachingInstrument_NewInstrumentMutation,
} from "@graphql";

type Props = {
  onInstrumentCreated?: (
    input: AddNewTeachingInstrument_NewInstrumentMutation["createTeachingInstrument"]["teacherOf"],
  ) => void;
  heading: string;
};

export const AddNewTeachingInstrument: React.FC<Props> = ({
  heading,
  onInstrumentCreated,
}) => {
  const { viewer } = useMaybeViewerContext();
  const [showDone, setShowDone] = useState<boolean>();
  const [createTeachingInstrument, { error }] =
    useAddNewTeachingInstrument_NewInstrumentMutation();

  const { data: possibleInstrumentsQueryData } =
    useAddNewTeachingInstrument_ExistingInstrumentsQuery();

  const { handleSubmit, register, setValue } = useForm<FormData>();

  type FormData = {
    instrumentId: string;
  };

  const onFormSubmit = async (formData: FormData) => {
    try {
      if (!viewer) {
        return;
      }

      const response = await createTeachingInstrument({
        variables: {
          input: {
            userId: viewer.id,
            instrumentId: formData.instrumentId,
          },
        },
        refetchQueries: [TeacherEditInstrumentProfileDocument],
        onCompleted() {
          setShowDone(true);
          setTimeout(() => {
            setShowDone(false);
            setValue("instrumentId", "");
          }, 5000);
        },
      });
      if (!response.data) {
        throw new Error("Failed to create learning instrument");
      }
      if (onInstrumentCreated) {
        onInstrumentCreated(response.data.createTeachingInstrument.teacherOf);
      }
    } catch (err) {
      console.error("onFormSubmit error: ", err);
    }
  };

  return (
    <div>
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        {heading && <Heading level={6}>{heading}</Heading>}

        <div className="flex flex-col">
          <InstrumentSelect
            labelText=""
            registerProps={register("instrumentId", { required: true })}
            removeInstruments={possibleInstrumentsQueryData?.viewer?.teacherOf.map(
              (to) => to.instrumentId,
            )}
          />
        </div>

        <div className="flex flex-col items-start">
          <Button type="submit" disabled={showDone}>
            Add Instrument
          </Button>

          <span
            className={clsx(
              "ml-4 text-green-600 bold",
              showDone
                ? " block opacity-100"
                : "hidden transition-all duration-600 opacity-0",
            )}
          >
            Done!
          </span>
        </div>

        {error && (
          <p className="mt-3 text-xs text-red-600">Error: {error.message}</p>
        )}
      </form>
    </div>
  );
};
