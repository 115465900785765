import { Conversation, Media } from "@twilio/conversations";
import { DateTime } from "luxon";
import { Message } from "postcss";

export const prepareMessage = (
  inAppConversation: Conversation,
  message: string,
  files: File[],
) => {
  const messageBuilder = inAppConversation.prepareMessage();
  if (message.length > 0) {
    messageBuilder.setBody(message);
  }

  if (files.length > 0) {
    for (const file of files) {
      const fileData = new FormData();
      fileData.set(file.name, file, file.name);
      messageBuilder.setBody(message).addMedia(fileData);
    }
  }
  return messageBuilder;
};

export const downloadMediafile = (blob: Blob, filename: string | null) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.setAttribute("style", "display: none");
  a.href = url;
  a.download = filename ? filename : "";
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};

export const getBlobFile = async (media: Media) => {
  try {
    const url = await getFileUrl(media);
    const response = await fetch(url);
    return response.blob();
  } catch (error) {
    console.error("getBlobFile error: ", error);
    throw new Error(error);
  }
};

export const getFileUrl = async (media: Media): Promise<string> => {
  return await media.getContentTemporaryUrl().then();
};

export const downloadMutipleHandler = async (message: Message) => {
  const attachedMedia = message.attachedMedia;
  if (!attachedMedia) {
    return;
  }
  for (const media of attachedMedia) {
    const blob = await getBlobFile(media);
    if (blob) downloadMediafile(blob, media.filename);
  }
};

export const conversationsDate = (dateTime: DateTime) => {
  const todayDateTime = DateTime.now();

  let label: string;
  if (dateTime.ordinal === todayDateTime.ordinal) {
    label = "Today";
  } else if (todayDateTime.ordinal - dateTime.ordinal === 1) {
    label = "Yesterday";
  } else {
    label = dateTime.toLocaleString(DateTime.DATE_SHORT);
  }
  return label;
};

export const getMessages = async (
  conversation: Conversation,
  pageSize: number | undefined,
  index: number,
) => {
  try {
    const _totalMessageCount = await conversation.getMessagesCount();
    let anchor = 0;
    if (index <= _totalMessageCount) {
      anchor = _totalMessageCount - index;
    }
    const messageList = await conversation.getMessages(pageSize, anchor);
    return messageList;
  } catch (error) {
    throw new Error(error);
  }
};
