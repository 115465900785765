import { useState } from "react";

import { clsx } from "clsx";

import { DrawerModal } from "@riffs/DrawerModal";
import { Heading } from "@riffs/Heading";
import LinkStyleButton from "@riffs/LinkStyleButton";

import { Chat } from "../Chat";
import { ConversationProvider } from "../ConversationContext";
import { useAppChat } from "./AppChatProvider";
import { ChannelList } from "./ChannelList";

export function AppChatDrawer() {
  const [forceFullscreenChat, setForceFullscreenChat] = useState(false);

  const {
    activeConversation,
    closeActiveConversation,
    isChatDrawerOpen,
    setIsChatDrawerOpen,
  } = useAppChat();

  const handleBackButtonClick = () => {
    if (isChatDrawerOpen && !activeConversation) {
      setIsChatDrawerOpen(false);
    }

    if (isChatDrawerOpen && activeConversation) {
      closeActiveConversation();
    }
  };

  return (
    <DrawerModal
      className="max-w-md"
      onClose={() => setIsChatDrawerOpen(false)}
      isDrawerOpen={isChatDrawerOpen}
    >
      <div className="h-screen flex flex-col">
        <div
          className={clsx(
            "flex w-full justify-between items-center border-b border-gray-700 transition-shadow",
            Boolean(activeConversation) && "shadow-md",
          )}
        >
          <LinkStyleButton
            className="px-4 py-4 self-start"
            onClick={handleBackButtonClick}
          >
            ← Back
          </LinkStyleButton>
          {activeConversation && (
            <Heading
              level={6}
              className={clsx(
                "px-4 overflow-hidden whitespace-nowrap text-ellipsis",
              )}
            >
              {activeConversation.conversationTitle}
            </Heading>
          )}
        </div>
        {!activeConversation && <ChannelList />}
        {activeConversation && (
          <ConversationProvider
            key={activeConversation.conversationId}
            conversationObj={activeConversation}
          >
            <Chat
              isILE={false}
              onClose={() => closeActiveConversation()}
              className="pointer-events-auto"
              conversationTitle={activeConversation.conversationTitle}
              forceFullscreenChat={forceFullscreenChat}
              toggleForceFullscreenChat={() =>
                setForceFullscreenChat(!forceFullscreenChat)
              }
            />
          </ConversationProvider>
        )}
      </div>
    </DrawerModal>
  );
}
