import { PropsWithChildren, ReactNode } from "react";

import clsx from "clsx";
import { Alert, FlowbiteColors } from "flowbite-react";

export const Banner = ({
  children,
  additionalContent,
  onDismiss,
  color = "warning",
}: {
  additionalContent?: ReactNode;
  color?: keyof FlowbiteColors;
  onDismiss?: () => void;
} & PropsWithChildren) => {
  return (
    <div
      className={clsx(
        "self-center",
        "w-full",
        "last:mb-6",
        "first:mt-6",
        "md:first:mt-0",
      )}
    >
      <Alert
        withBorderAccent={true}
        onDismiss={onDismiss}
        color={color}
        className={clsx("!text-xs", "w-full")}
        additionalContent={additionalContent}
      >
        {children}
      </Alert>
    </div>
  );
};
