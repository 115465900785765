import { Heading } from "@riffs/Heading";
import { Loading } from "@riffs/Loading";
import { StyledText } from "@riffs/StyledText";

import { useInvitation_RetrieveByTokenQuery } from "@graphql";

export const Invitation = ({
  invitationToken,
}: {
  invitationToken: string;
}) => {
  const { data: invitationData, loading: invitationLoading } =
    useInvitation_RetrieveByTokenQuery({
      skip: !invitationToken,
      variables: { token: invitationToken },
    });

  if (invitationLoading) {
    return <Loading text="Loading invitation..." />;
  }

  const teachingRelationshipInvitation =
    invitationData?.pendingTeachingRelationshipInvitationByToken;

  if (!teachingRelationshipInvitation) {
    return null;
  }

  const initiatorName = teachingRelationshipInvitation.initiatedBy.name;
  const joiningName =
    teachingRelationshipInvitation.studioInvitingUser?.studio?.name;

  return (
    <div className="mb-3">
      <Heading level={4} className="mb-1">
        You're invited to join Forte
      </Heading>
      {joiningName && (
        <StyledText size="sm">
          <b>{initiatorName}</b> invited you to join <b>{joiningName}</b>.
        </StyledText>
      )}
    </div>
  );
};
