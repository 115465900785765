export enum ADDITIONAL_JOIN_PARAMS {
  INSTRUMENT_ID = "instrumentId",
  REDIRECT_URL = "redirectUrl",
}

export const JOIN_MODE_KEY = "joinMode";

export type ExtraSignupParameters = {
  [ADDITIONAL_JOIN_PARAMS.INSTRUMENT_ID]?: string;
  [ADDITIONAL_JOIN_PARAMS.REDIRECT_URL]?: string;
};

export enum SignupModeOptions {
  LEARNER = "learner",
  PARENT = "parent",
  TEACHER = "teacher",
}

export type SignupModeT = SignupModeOptions | null;
