import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { type ApolloQueryResult } from "@apollo/client";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";

import { directlySetWindowLocation } from "@util/directlySetWindowLocation";
import { logger } from "@util/logger";

import { type RoleT, RoleTypeEnum } from "../constants/role";
import {
  getRedirectPathForRole,
  isPathInArray,
  roleAgnosticPaths,
  roleCanViewRoute,
  viewerOptionalPaths,
} from "../helpers/routes";
import { SessionStatus } from "./AuthGatekeeper";

import {
  type OrganizationPaymentAccountStatus,
  type ViewerContextQuery,
  useUpdateUserPreferencesMutation,
  useViewerContextQuery,
} from "@graphql";

export type ViewerT = NonNullable<ViewerContextQuery["viewer"]>;

type ViewerContextT = {
  allRoles: RoleT[];
  currentRole: RoleT | null;
  isViewerLoading: boolean;
  refetchViewerContext: () => Promise<ApolloQueryResult<ViewerContextQuery>>;
  setAutoRoleRedirect: Dispatch<SetStateAction<boolean>>;
  updateCurrentRole: Dispatch<SetStateAction<RoleT>>;
  viewer: ViewerContextQuery["viewer"] | null;
};

const ViewerContext = createContext<ViewerContextT>({
  allRoles: [],
  currentRole: null,
  updateCurrentRole: () => {},
  setAutoRoleRedirect: () => {},
  viewer: null,
  refetchViewerContext: () =>
    Promise.reject(new Error("Uninitialized viewer context")),
  isViewerLoading: false,
});

export function getOrganizationPaymentAccountStatusOfStudentOrParent(
  currentRole: RoleT,
): OrganizationPaymentAccountStatus | undefined {
  if (currentRole.type === RoleTypeEnum.STUDENT_OR_PARENT) {
    const organizationPaymentAccount = currentRole.organization.paymentAccount;
    return organizationPaymentAccount?.status;
  } else {
    return undefined;
  }
}

export const useMaybeViewerContext = () => useContext(ViewerContext);

// eslint-disable-next-line max-lines-per-function
export const MaybeViewerProvider = ({ children }: PropsWithChildren) => {
  const session = useSession();
  const {
    data,
    loading,
    refetch: refetchViewer,
  } = useViewerContextQuery({
    errorPolicy: "all", // the app is crippled if it has an error, so we'll be more tolerant of errors here
  });
  const router = useRouter();
  const cognitoUserId =
    (session.data &&
      "accessToken" in session.data &&
      session.data.cognitoUserId) ||
    null;

  const localStorageKeyForRole = `currentRole-${cognitoUserId}`; // `currentRole-d617bbb1-fab9-4e72-8378-e67e5f9e1389`

  const [requestedCurrentRole, setRequestedCurrentRole] =
    useState<RoleT | null>(getRoleFromLocalStorage(localStorageKeyForRole));

  const [autoRoleRedirect, setAutoRoleRedirect] = useState(true);

  const viewer = data?.viewer;

  const [
    updateUserPreferences,
    {
      called: updateUserPreferencesCalled,
      loading: userPreferencesUpdateLoading,
    },
  ] = useUpdateUserPreferencesMutation();

  useEffect(() => {
    // If the user doesn't have a saved timezone preference, save the browser's timezone to UserPreferences.
    if (
      viewer &&
      !viewer.userPreferences?.timezone &&
      !userPreferencesUpdateLoading &&
      !updateUserPreferencesCalled
    ) {
      /* The API/docs for this are inconsistent; TS thinks it's always a string, but API docs say that
      it could be undefined or could default to a default (and possibly also empty string?). */
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // eslint-disable-next-line no-console
      console.debug("setting browserTimezone to :", browserTimezone);

      updateUserPreferences({
        variables: {
          input: {
            userId: viewer.id,
            timezone: browserTimezone,
          },
        },
      });
    }
  }, [
    updateUserPreferences,
    updateUserPreferencesCalled,
    userPreferencesUpdateLoading,
    viewer,
  ]);

  const studentOrParentRoles = useMemo(() => {
    return (
      (viewer &&
        viewer.organizations.map((organization) => ({
          type: RoleTypeEnum.STUDENT_OR_PARENT as const,
          organizationName: organization.name,
          organizationId: organization.id,
          organization,
          organizationStillRequiresVerifiedAdult:
            organization.requiresVerifiedAdult &&
            !organization.paymentAccount?.isPayerVerifiedAdult,
          isAdmin: organization.viewerRelationship.isAdmin,
          viewerNumTeachingRelationshipsAsLearner:
            viewer.viewerNumTeachingRelationshipsAsLearner,
        }))) ||
      []
    );
  }, [viewer]);

  const teacherRoles = useMemo(() => {
    return viewer?.teacherStudios.length
      ? [{ type: RoleTypeEnum.TEACHER as const }]
      : [];
  }, [viewer]);

  const adminRoles = useMemo(() => {
    return viewer?.viewerIsForteAdmin
      ? [{ type: RoleTypeEnum.FORTE_ADMIN as const }]
      : [];
  }, [viewer]);

  const allRoles = useMemo(
    () => [...adminRoles, ...teacherRoles, ...studentOrParentRoles],
    [adminRoles, studentOrParentRoles, teacherRoles],
  );

  const currentRole = getCurrentRole();

  useEffect(() => {
    if (!viewer) {
      return;
    }

    logger.updateUser({
      id: viewer.id,
      name: viewer.givenNameAndInitialOfFamilyName,
      email: viewer?.email || undefined,
      role: currentRole?.type,
    });
  }, [currentRole, viewer]);

  const updateCurrentRole = useCallback(
    (newRole: RoleT) => {
      if (!cognitoUserId) {
        console.error("Invalid session.data.cognitoUserId");
        return;
      }

      if (newRole) {
        localStorage.setItem(localStorageKeyForRole, JSON.stringify(newRole));
      } else {
        localStorage.removeItem(localStorageKeyForRole);
      }

      setRequestedCurrentRole(newRole);

      if (!roleCanViewRoute(newRole, router.asPath)) {
        // eslint-disable-next-line no-console
        console.debug("Invalid route for role; redirecting to Role Homepage");
        directlySetWindowLocation(getRedirectPathForRole(newRole));
      }
    },
    [cognitoUserId, localStorageKeyForRole, router.asPath],
  );

  function getCurrentRole(): null | RoleT {
    if (!requestedCurrentRole) {
      return null;
    }

    if (requestedCurrentRole.type === RoleTypeEnum.TEACHER) {
      return teacherRoles.length > 0 ? { type: RoleTypeEnum.TEACHER } : null;
    } else if (requestedCurrentRole.type === RoleTypeEnum.STUDENT_OR_PARENT) {
      const foundRole = studentOrParentRoles.find((role) => {
        return role.organizationId === requestedCurrentRole.organizationId;
      });
      return foundRole ? foundRole : null;
    } else if (requestedCurrentRole.type === RoleTypeEnum.FORTE_ADMIN) {
      return adminRoles.length > 0 ? { type: RoleTypeEnum.FORTE_ADMIN } : null;
    }

    return null;
  }

  const providerValue = useMemo(
    () => ({
      allRoles,
      currentRole,
      updateCurrentRole,
      setAutoRoleRedirect,
      viewer,
      isViewerLoading: loading || userPreferencesUpdateLoading,
      refetchViewerContext: () => {
        return refetchViewer();
      },
    }),
    [
      allRoles,
      currentRole,
      loading,
      refetchViewer,
      setAutoRoleRedirect,
      updateCurrentRole,
      userPreferencesUpdateLoading,
      viewer,
    ],
  );
  /**
   * We want to block the page from rendering on the initial render, but not if you're
   * transitioning from nonauthed->authed (and to a lesser extent, also not when transitioning
   * from authed->nonauthed).
   * Without this extra `!data` check, your previously mounted component (e.g. SignIn) would be
   * mounted, then unmounted here, then remounted as soon as the ViewerContextQuery returned
   */
  if (loading && !data) {
    return null;
  }

  if (!loading && data && session.status === SessionStatus.AUTHENTICATED) {
    /* No currentRole (either because we didn't request one or we did and we didn't find it).
    (Maybe you got kicked out or stopped impersonating?) */

    // eslint-disable-next-line unicorn/no-lonely-if
    if (currentRole === null && allRoles.length === 1) {
      // eslint-disable-next-line no-console

      if (!autoRoleRedirect) {
        return null;
      }

      // eslint-disable-next-line no-console
      console.debug("No currentRole and only 1 to choose from; setting");
      updateCurrentRole(allRoles[0]);
      // return null; // when transitioning (e.g. login), you might not have a currentRole, which is fine, it'll be fixed imminently
    }
  }

  if (
    isPathInArray(router.pathname, [
      ...viewerOptionalPaths,
      ...roleAgnosticPaths,
    ])
  ) {
    return (
      <ViewerContext.Provider value={providerValue}>
        {children}
      </ViewerContext.Provider>
    );
  }

  if (session.status === SessionStatus.AUTHENTICATED) {
    // Has currentRole but isn't allowed to see the current route.

    // eslint-disable-next-line unicorn/no-lonely-if
    if (currentRole && !roleCanViewRoute(currentRole, router.asPath)) {
      // eslint-disable-next-line no-console
      console.debug(
        "Has currentRole but on an invalid route; redirecting to Role Homepage",
      );
      directlySetWindowLocation(getRedirectPathForRole(currentRole));
      return null;
    }
  }

  return (
    <ViewerContext.Provider value={providerValue}>
      {children}
    </ViewerContext.Provider>
  );
};

function getRoleFromLocalStorage(localStorageKeyForRole: string): null | RoleT {
  if (typeof localStorage === "undefined") {
    return null;
  }

  const roleFromLocalStorage = localStorage.getItem(localStorageKeyForRole);
  if (!roleFromLocalStorage) {
    return null;
  }

  const parsedJson = JSON.parse(roleFromLocalStorage);

  // migrating old role shape to the new shape
  if (parsedJson.id && !parsedJson.organizationId) {
    parsedJson.organizationId = parsedJson.id;
    parsedJson.organizationName = parsedJson.name || " ";
    delete parsedJson.id;
    delete parsedJson.name;
    localStorage.setItem(localStorageKeyForRole, JSON.stringify(parsedJson));
  }

  return parsedJson;
}
