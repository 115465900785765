// https://iconoir.com/
import * as Iconoir from "iconoir-react";

// ExtraIcon icons need to also support width, height, and color props
type ExtraIconProps = {
  width?: number | string;
  height?: number | string;
  color?: string;
  style?: React.CSSProperties;
};

const ICONOIR_DEFAULT_WIDTH = "1.5em";
const ICONOIR_DEFAULT_HEIGHT = "1.5em";

// ExtraIcon.Crown
const Crown = ({
  color = "currentColor",
  width = ICONOIR_DEFAULT_WIDTH,
  height = ICONOIR_DEFAULT_HEIGHT,
  style,
}: ExtraIconProps) => (
  // Borrowed from `TbCrown` in https://react-icons.github.io/react-icons/search?q=tbcrown
  // TODO: Figure out why Crown icon still shrinks too small on small screens. See `pages/student/my-family.tsx`.
  <svg
    stroke={color}
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <path d="M12 6l4 6l5 -4l-2 10h-14l-2 -10l5 4z"></path>
  </svg>
);

// ExtraIcon.VideoCamera
const VideoCamera = ({
  color = "currentColor",
  width = ICONOIR_DEFAULT_WIDTH,
  height = ICONOIR_DEFAULT_HEIGHT,
}: ExtraIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2737 15.4715L19.0674 12.301V14.2304C19.0674 16.0764 17.6457 17.5678 15.8936 17.5678H3.21837C1.46626 17.5678 0.0445557 16.0764 0.0445557 14.2304V4.20795C0.0445557 2.36198 1.46626 0.870605 3.21837 0.870605H15.8936C17.6457 0.870605 19.0674 2.36198 19.0674 4.20795V6.13735L24.2737 2.96688C24.7142 2.96688 25.0746 3.32147 25.0746 3.74907V14.6893C25.0746 15.1169 24.7142 15.4715 24.2737 15.4715ZM24.0734 4.30181L18.3666 7.77474L18.1363 7.92075H18.0662V3.99937C18.0662 2.85215 17.1651 1.91353 16.0638 1.91353C14.9625 1.91353 9.55599 1.91353 9.55599 1.91353H3.04817C1.94684 1.91353 1.04576 2.85215 1.04576 3.99937V14.4286C1.04576 15.5862 1.94684 16.5144 3.04817 16.5144H16.0638C17.1651 16.5144 18.0662 15.5862 18.0662 14.4286V10.5176H18.1363L18.3666 10.6636L24.0734 14.1366V4.29138V4.30181Z"
      fill={color}
    />
  </svg>
);

// Export all Iconoir icons as Icon so we can call like `<Icon.PizzaSlice />` etc
export const Icon = Iconoir;

// Export other non-Iconoir icons as ExtraIcon so we can call them like `<ExtraIcon.Crown />`
export const ExtraIcon = {
  /** Crown to denote "administrator" */
  Crown,
  /** Video Camera icon from Figma file (2022-09-20) */
  VideoCamera,
};
