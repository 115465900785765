import { PropsWithChildren, createElement } from "react";

import clsx from "clsx";

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

const headingComponentLookup = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
} as const;

const textClassnameLevelLookup = {
  1: "text-4xl font-bold",
  2: "text-3xl font-bold",
  3: "text-2xl font-bold",
  4: "text-xl font-bold",
  5: "text-lg font-semibold",
  6: "text-base font-semibold",
} as const;

export function Heading({
  level = 1,
  children,
  className = "",
  secondary = false,
  ...headingElementProps // TODO needs better typing
}: {
  level?: HeadingLevel;
  className?: string;
  id?: string;
  secondary?: boolean;
} & PropsWithChildren) {
  const sharedHeadingClassName = secondary
    ? "text-gray-500"
    : "text-gray-900 dark:text-white";
  const headingClassName = clsx(
    `${textClassnameLevelLookup[level]}`,
    sharedHeadingClassName,
    className,
  );
  return createElement(
    headingComponentLookup[level],
    { className: headingClassName, ...headingElementProps },
    children,
  );
}
