import uniq from "lodash/uniq";

import { type DisambiguatingTeachingRelationshipFragment } from "@graphql";

/**
 * Option labels logic
 *
 * (when joining as yourself):
 * - [normal]                           "Charlie Froh
 * - [multiple studios]                 "Charlie Froh (Suzuki Violin School)"
 * - [multiple instruments]             "Charlie Froh (Violin)"
 * - [multiple studios and instruments] "Charlie Froh (Violin, Suzuki Violin School)"
 */
export function getDisambiguatingLearnerSelectorLabel(
  relationship: DisambiguatingTeachingRelationshipFragment,
  allRelationships: Array<DisambiguatingTeachingRelationshipFragment>,
  _options: {} = {},
) {
  // return `${relationship.learner.givenNameAndInitialOfFamilyName} (${relationship.instrument.name} @ ${relationship.studio.name}) as ${relationship.learner.givenNameAndInitialOfFamilyName}`;
  const relationshipsForLearner = allRelationships.filter(
    (rel) => rel.learner.id === relationship.learner.id,
  );

  const allRelationshipsAreSameInstrument =
    uniq(allRelationships.map((rel) => rel.instrument.id)).length === 1;

  const allRelationshipsAreSameStudio =
    uniq(allRelationships.map((rel) => rel.studio.id)).length === 1;

  const learnerOnlyHasOneStudio =
    uniq(relationshipsForLearner.map((rel) => rel.studio.id)).length === 1;

  if (allRelationshipsAreSameInstrument) {
    if (allRelationshipsAreSameStudio || learnerOnlyHasOneStudio) {
      // "Charlie Froh"
      return `${relationship.learner.givenNameAndInitialOfFamilyName}`;
    }

    // "Charlie Froh (Suzuki Violin School)"
    return `${relationship.learner.givenNameAndInitialOfFamilyName} (${relationship.studio.name})`;
  }

  if (allRelationshipsAreSameStudio || learnerOnlyHasOneStudio) {
    // "Charlie Froh (Violin)"
    return `${relationship.learner.givenNameAndInitialOfFamilyName} (${relationship.instrument.name})`;
  }

  // "Charlie Froh (Violin, Suzuki Violin School)"
  return `${relationship.learner.givenNameAndInitialOfFamilyName} (${relationship.instrument.name}, ${relationship.studio.name})`;
}
