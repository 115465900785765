type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

// https://stackoverflow.com/posts/59703424/revisions
export async function promiseAllNamed<
  T extends Record<string, Promise<any>>,
  TResolved extends { [P in keyof T]: ThenArg<T[P]> },
>(nameToPromise: T): Promise<TResolved> {
  const entries = Object.entries(nameToPromise);
  const results = await Promise.all(entries.map((e) => e[1]));
  const nameToResult: TResolved = {} as TResolved;
  for (let i = 0; i < results.length; ++i) {
    const name: keyof T = entries[i][0];
    nameToResult[name] = results[i];
  }
  return nameToResult;
}
