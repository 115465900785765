export const GUITAR = "guitar";
/**
 * Acoustic guitar should no longer be allowed as a choice for teachers when creating a teacherOf or for students creating a learnerOf.
 * See `guitarIdsToBeCombined`.
 */
export const ACOUSTIC_GUITAR = "acoustic_guitar";
export const PIANO = "piano";
export const VOICE = "voice";
export const VIOLIN = "violin";
export const BANJO = "banjo";

/**
 * "Acoustic guitar needs" to be considered the same as "guitar": https://app.asana.com/0/1203058193756759/1205701337504746/f
 */
export const guitarIdsToBeCombined = [GUITAR, ACOUSTIC_GUITAR];

/**
 * The 3 original instruments that Forte launched with.
 */
export const ogInstrumentIds = [PIANO, VOICE, VIOLIN];

export const popularInstrumentIds = [GUITAR, PIANO, VOICE];

export const defaultInstrumentId = VIOLIN;

export const billableInstrument = BANJO;
