import {
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import ReactGA from "react-ga4";

import some from "lodash/some";
import { useRouter } from "next/router";
import { useLocalStorage } from "usehooks-ts";

import { JOIN_MODE_KEY, SignupModeOptions } from "@helpers/joinHelpers";

import { type RoleT } from "../constants/role";
import { STUDIO_ILE } from "../helpers/routes";
import { useImpersonation } from "./Impersonation";
import { type ViewerT, useMaybeViewerContext } from "./MaybeViewerProvider";
import { SwitchRoleAsPage } from "./SwitchRole/SwitchRoleAsPage";
import { TermsOfUseRoadblockAsPage } from "./TermsOfUseRoadblock";

export type ViewerContextType = {
  allRoles: RoleT[];
  currentRole: RoleT;
  viewer: ViewerT;
  viewerJoinedAsParent: boolean;
};

const AuthedViewer = createContext<ViewerContextType>(
  null as unknown as ViewerContextType,
);

const IgnoreTermsOfUseForPages = [STUDIO_ILE];

export const useAuthedViewer = () => useContext(AuthedViewer);

export const AuthedViewerProvider = ({ children }: PropsWithChildren) => {
  const { allRoles, currentRole, viewer } = useMaybeViewerContext();
  const { impersonating } = useImpersonation();
  const router = useRouter();
  const [joinModeFlag] = useLocalStorage<SignupModeOptions | null>(
    JOIN_MODE_KEY,
    null,
  );

  useEffect(() => {
    if (viewer?.id && !impersonating) {
      ReactGA.gtag("set", { user_id: viewer.id });
    } else {
      ReactGA.gtag("set", { user_id: null });
    }
  }, [viewer?.id, impersonating]);

  const viewerJoinedAsParent = joinModeFlag === SignupModeOptions.PARENT;

  const providerValue: ViewerContextType | null = useMemo(() => {
    if (!viewer || !currentRole) {
      return null;
    }

    return {
      allRoles,
      currentRole,
      viewer,
      viewerJoinedAsParent,
    };
  }, [allRoles, currentRole, viewer, viewerJoinedAsParent]);

  if (!viewer) {
    return null;
  }

  if (!currentRole) {
    return <SwitchRoleAsPage />;
  }

  if (!providerValue) {
    return null;
  }

  const showTermsOfUse =
    !impersonating &&
    viewer.viewerMustAcceptTermsOfUse &&
    !some(IgnoreTermsOfUseForPages, (route) =>
      router.pathname.startsWith(route),
    );

  return (
    <AuthedViewer.Provider value={providerValue}>
      {showTermsOfUse && <TermsOfUseRoadblockAsPage />}
      {!showTermsOfUse && children}
    </AuthedViewer.Provider>
  );
};
