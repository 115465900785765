import { Spinner } from "flowbite-react";

import { StyledText } from "@riffs/StyledText";

export const Loading = ({
  className = "flex gap-3 h-8", // Height must be tall enough such that the Spinner doesn't cause FormResults to intermittently show a vertical scrollbar because of 'overflow-y-auto'.
  text = "Loading...",
}: {
  className?: string;
  text?: string;
}) => {
  const space = text ? " " : "";

  return (
    <StyledText className={className}>
      <Spinner size="sm" />
      {space}
      {text}
    </StyledText>
  );
};
