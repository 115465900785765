import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from "@apollo/client";
import { IncomingHttpHeaders } from "http";
import { v4 as uuidv4 } from "uuid";

import { IS_LOCALHOST } from "../constants/config";

// On the client, we store the Apollo Client in the following variable to prevent the client from reinitializing between page transitions.
let globalApolloClientForFrontend: ApolloClient<NormalizedCacheObject> | null =
  null;

/**
 * Get the protocol, hostname, and port from the window if we have it. Otherwise, get it from IncomingHttpHeaders.
 *
 * We're dynamically constructing the graphQlServerUri because our Vercel deployments can have nondeterministic
 * URLs (opus-fortelessons-{build ID}.vercel.app), and the browser's hostname might differ from the environment
 * variable in Vercel, which breaks the auth cookie since it has a different hostname.
 * https://vercel.com/docs/deployments/generated-urls
 *
 * We also have to check for `window` because server-side rendering does not have a window; it's unclear whether we can
 * actually turn off SSR or not.
 *
 * https://github.com/auth0/nextjs-auth0/issues/420#issuecomment-865357497
 */
function getUrl(incomingHttpHeaders?: IncomingHttpHeaders): string {
  if (typeof window === "undefined") {
    if (incomingHttpHeaders) {
      const host = incomingHttpHeaders["host"];
      const protocol = IS_LOCALHOST ? "http" : "https";
      const result = `${protocol}://${host}`;
      return result;
    } else {
      return "";
    }
  } else {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  }
}

// Add viewer ID to headers if we have a debug viewer ID
const debugViewerLink = new ApolloLink((operation, forward) => {
  let debugViewerId: string | null | undefined = undefined;
  if (typeof window !== "undefined") {
    debugViewerId = localStorage.getItem("debugViewerId");
  }

  if (debugViewerId) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "x-viewer-id": debugViewerId,
      },
    }));
  }
  return forward(operation);
});

const sessionGuidLink = new ApolloLink((operation, forward) => {
  let sessionGuid: string | null | undefined = undefined;
  if (typeof window === "undefined") {
    // https://github.com/fortelessons/opus/pull/1022#discussion_r1346304809
    sessionGuid = "server";
  } else {
    sessionGuid = localStorage.getItem("sessionGuid");
    if (!sessionGuid) {
      localStorage.setItem("sessionGuid", uuidv4());
      sessionGuid = localStorage.getItem("sessionGuid");
    }
  }
  operation.setContext((context: Record<string, any>) => {
    return {
      headers: {
        ...(context.headers || {}),
        "x-session-guid": sessionGuid,
      },
    };
  });
  return forward(operation);
});

const requestIdLink = new ApolloLink((operation, forward) => {
  operation.setContext((context: Record<string, any>) => {
    const requestId = context.requestId || uuidv4();
    return {
      requestId,
      headers: {
        ...(context.headers || {}),
        "x-request-id": requestId,
      },
    };
  });
  return forward(operation);
});

/**
 * ? What happens if the server gets both Authorization and Viewer ID?
 *
 * Maybe server ignores Authorization if Viewer ID exists?
 */

function createApolloClient(incomingHttpHeaders?: IncomingHttpHeaders) {
  const graphQlServerUri = getUrl(incomingHttpHeaders);

  const httpLink = new HttpLink({
    uri: `${graphQlServerUri}/api/graphql`,
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            user: {
              read(_existing, { args, toReference }) {
                if (!args?.id) {
                  return;
                }

                return toReference({
                  __typename: "User",
                  id: args.id,
                });
              },
            },
            teachingRelationship: {
              read(_existing, { args, toReference }) {
                if (!args?.id) {
                  return;
                }

                return toReference({
                  __typename: "TeachingRelationship",
                  id: args.id,
                });
              },
            },
            lesson: {
              read(_existing, { args, toReference }) {
                if (!args?.id) {
                  return;
                }

                return toReference({
                  __typename: "Lesson",
                  id: args.id,
                });
              },
            },
            teacherOf: {
              read(_existing, { args, toReference }) {
                if (!args?.id) {
                  return;
                }

                return toReference({
                  __typename: "TeacherOf",
                  id: args.id,
                });
              },
            },
            userTeacherOfFlag: {
              read(_existing, { args, toReference }) {
                if (!args?.id) {
                  return;
                }

                return toReference({
                  __typename: "UserTeacherOfFlag",
                  id: args.id,
                });
              },
            },
            matchingRequestById: {
              read(_existing, { args, toReference }) {
                if (!args?.id) {
                  return;
                }

                return toReference({
                  __typename: "MatchingRequest",
                  id: args.id,
                });
              },
            },
          },
        },
      },
    }),
    link: from([requestIdLink, debugViewerLink, sessionGuidLink, httpLink]),
  });

  return apolloClient;
}

/**
 * Inspired by https://hasura.io/learn/graphql/nextjs-fullstack-serverless/apollo-client/.
 * https://www.apollographql.com/blog/apollo-client/next-js/next-js-getting-started/
 * https://www.apollographql.com/blog/apollo-client/next-js/how-to-use-apollo-client-with-next-js-13/ (once we upgrade to Next.js 13) is probably like https://www.prisma.io/docs/guides/other/troubleshooting-orm/help-articles/nextjs-prisma-client-dev-practices
 */
export function getApolloClient(incomingHttpHeaders?: IncomingHttpHeaders) {
  // Make sure to create a new client for every server-side request so that data isn't shared between connections (which would be bad).
  if (typeof window === "undefined") {
    return createApolloClient(incomingHttpHeaders);
  }

  // Reuse client on the client-side:
  if (!globalApolloClientForFrontend) {
    globalApolloClientForFrontend = createApolloClient(incomingHttpHeaders);
  }

  return globalApolloClientForFrontend;
}
