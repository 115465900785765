import uniq from "lodash/uniq";

import { type DisambiguatingTeachingRelationshipFragment } from "@graphql";

/**
 * Option labels logic
 *
 * (when joining as yourself):
 * - [normal]                           "Shinichi Suzuki"
 * - [multiple studios]                 "Shinichi Suzuki (Suzuki Violin School)"
 * - [multiple instruments]             "Shinichi Suzuki (Violin)"
 * - [multiple studios and instruments] "Shinichi Suzuki (Violin, Suzuki Violin School)"
 *
 * (when joining as Charlie):
 * - [normal]                           "Shinichi Suzuki (join as Charlie)"
 * - [multiple studios]                 "Shinichi Suzuki (Suzuki Violin School) (join as Charlie)"
 * - [multiple instruments]             "Shinichi Suzuki (Violin) (join as Charlie)"
 * - [multiple studios and instruments] "Shinichi Suzuki (Violin, Suzuki Violin School) (join as Charlie)"
 */
export function getDisambiguatingTeacherSelectorLabel(
  relationship: DisambiguatingTeachingRelationshipFragment,
  allRelationships: Array<DisambiguatingTeachingRelationshipFragment>,
  viewerId: string,
  options: {
    includeChildName?: boolean;
    joinLabelPrefix?: string;
  } = {},
) {
  // return `${relationship.teacher.name} (${relationship.instrument.name} @ ${relationship.studio.name}) as ${relationship.learner.name}`;
  const relationshipsForTeacher = allRelationships.filter(
    (rel) => rel.teacher.id === relationship.teacher.id,
  );

  const allRelationshipsAreSameInstrument =
    uniq(allRelationships.map((rel) => rel.instrument.id)).length === 1;

  const allRelationshipsAreSameStudio =
    uniq(allRelationships.map((rel) => rel.studio.id)).length === 1;

  const teacherOnlyHasOneStudio =
    uniq(relationshipsForTeacher.map((rel) => rel.studio.id)).length === 1;

  const joinLabelPrefix = options?.joinLabelPrefix || "join as";
  const includeChildName = options?.includeChildName ?? true;
  const joinAsLabel =
    relationship.learner.id !== viewerId && includeChildName
      ? ` (${joinLabelPrefix} ${relationship.learner.givenNameAndInitialOfFamilyName})`
      : "";

  if (allRelationshipsAreSameInstrument) {
    if (allRelationshipsAreSameStudio || teacherOnlyHasOneStudio) {
      // "Shinichi Suzuki (join as Charlie)"
      return `${relationship.teacher.givenNameAndInitialOfFamilyName}${joinAsLabel}`;
    }

    // "Shinichi Suzuki (Suzuki Violin School) (join as Charlie)"
    return `${relationship.teacher.givenNameAndInitialOfFamilyName} (${relationship.studio.name})${joinAsLabel}`;
  }

  if (allRelationshipsAreSameStudio || teacherOnlyHasOneStudio) {
    // "Shinichi Suzuki (Violin) (join as Charlie)"
    return `${relationship.teacher.givenNameAndInitialOfFamilyName} (${relationship.instrument.name})${joinAsLabel}`;
  }

  // "Shinichi Suzuki (Violin, Suzuki Violin School) (join as Charlie)"
  return `${relationship.teacher.givenNameAndInitialOfFamilyName} (${relationship.instrument.name},  ${relationship.studio.name})${joinAsLabel}`;
}
