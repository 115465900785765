import { type PropsWithChildren } from "react";

import { twMerge } from "tailwind-merge";

export function ExternalLink({
  children,
  className,
  href,
  includeIcon = true,
  color = "blue",
}: {
  className?: string;
  href: string;
  includeIcon?: boolean;
  color?: "blue" | "black" | null;
} & PropsWithChildren) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={twMerge(
        className,
        "hover:underline",
        color === "blue" && "text-forteBlue dark:text-blue-400",
        color === "black" && "text-black dark:text-black",
        color === null && "underline",
      )}
    >
      {children}
      {includeIcon && " ↗"}
    </a>
  );
}
