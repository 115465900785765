import { useEffect } from "react";

import { useRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";

const DEFAULT_SHALLOW = true;

/**
 * TODO: We should probably rely on a well-tested external library (next-usequerystate?) for this instead of coding our own. Wait until https://github.com/47ng/next-usequerystate/issues/414 is resolved.
 * How does this relate to https://reactrouter.com/en/main/hooks/use-search-params, useQueryParams, and next-usequerystate?
 *
 * If we're really going to keep this instead of an external library:
 * TODO: Move to hooks subfolder.
 * TODO: Add tests.
 */
export const useNextQuery = <T extends ParsedUrlQueryInput>(config?: {
  initialState?: () => T;
  onQueriesChanged?: (queries: T) => void;
}): {
  queries: T;
  addQueries: (
    newQueries: T,
    config?: { shallow: boolean },
  ) => Promise<boolean>;
  deleteQueries: (
    queryKeys: string[],
    config?: { shallow: boolean },
  ) => Promise<boolean>;
  updateQueries: (
    newQueries: T,
    config?: { shallow: boolean },
  ) => Promise<boolean>;
  clearQueries: () => void;
} => {
  const router = useRouter();

  const initialQueries = (config?.initialState && config.initialState()) || {};

  const addQueries = async (
    newQueries: T,
    config?: { shallow: boolean },
  ): Promise<boolean> => {
    const currentQueries = { ...router.query, ...newQueries };
    return router.push(
      { pathname: router.pathname, query: currentQueries },
      undefined,
      { shallow: config?.shallow || DEFAULT_SHALLOW },
    );
  };

  const deleteQueries = async (
    queryKeys: Array<keyof T>,
    config?: { shallow: boolean },
  ): Promise<boolean> => {
    const currentQueries = { ...router.query } as T;

    queryKeys.forEach((queryKey) => {
      delete currentQueries[queryKey];
    });
    return router.push(
      { pathname: router.pathname, query: currentQueries },
      undefined,
      { shallow: config?.shallow || DEFAULT_SHALLOW },
    );
  };

  /**
   * This function is needed (instead of just addQueries and deleteQueries) because sometimes
   * we need to simultaneously delete a query param while adding or updating another one.
   * TODO: Reduce duplication with addQueries and deleteQueries.
   */
  async function updateQueries(
    newQueries: T,
    config?: { shallow: boolean },
  ): Promise<boolean> {
    const currentQueries = { ...router.query } as T;

    const keysToDelete: string[] = [];
    Object.keys(newQueries).forEach((queryKey) => {
      if (newQueries[queryKey] === undefined) {
        keysToDelete.push(queryKey);
      }
    });
    keysToDelete.forEach((queryKey) => {
      delete currentQueries[queryKey];
      delete newQueries[queryKey];
    });

    const updatedQueries = { ...currentQueries, ...newQueries };
    return router.push(
      { pathname: router.pathname, query: updatedQueries },
      undefined,
      { shallow: config?.shallow || DEFAULT_SHALLOW },
    );
  }

  const clearQueries = () => {
    router.push({ pathname: router.pathname });
  };

  useEffect(() => {
    const handleRouteChange = () => {
      const currentQueries = { ...router.query } as T;
      if (config?.onQueriesChanged) {
        config.onQueriesChanged(currentQueries);
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router, config]);

  return {
    queries: { ...initialQueries, ...(router.query as T) },
    addQueries,
    deleteQueries,
    updateQueries,
    clearQueries,
  };
};
