// https://moment.github.io/luxon/#/formatting?id=table-of-tokens
import { DateTime, Zone } from "luxon";

import { DayOfWeek } from "@prisma/client";

export const DATE_FORMAT_HH_MM = "HH:mm";

/**
 * E.g. "2023-12-31"
 */
export const DATE_FORMAT_HYPHENATED_YYYY_MM_DD = "yyyy-MM-dd";

/**
 * E.g. "2023-12-31 15:30"
 */
export const DATE_FORMAT_YEAR_TO_MINUTE = `${DATE_FORMAT_HYPHENATED_YYYY_MM_DD} ${DATE_FORMAT_HH_MM}`;
export const UTC = "UTC";

/**
 * E.g. "1:30 PM EDT"
 */
export const TIME_WITH_ABBREV_ZONE = "t ZZZZ";

/**
 * E.g. "America/New_York"
 */
export const IANA_ZONE = "z";

/**
 * E.g. "1:30 PM"
 */
export const TWELVE_HOUR_TIME = "h:mm a";

/**
 * E.g. "Mon 12/16/2024 1:30 PM EST"
 */
export const DAY_DATE_TIME_ZONE = `EEE MM/dd/yyyy ${TWELVE_HOUR_TIME} ZZZZ`;

/**
 * E.g. "Saturday"
 */
export const DAY_OF_WEEK = "EEEE";

/**
 * E.g. "Monday December 16, 2024 at 1:30 PM EST"
 */
export const LONG_DAY_DATE_AT_TIME_WITH_ZONE = `${DAY_OF_WEEK} MMMM d, yyyy 'at' ${TWELVE_HOUR_TIME} ZZZZ`;

/**
 * E.g. "Wednesday, August 6, 2014 9:00 am EST"
 */
export const LONG_DAY_COMMA_DATE_TIME_WITH_ZONE = `DDDD ${TIME_WITH_ABBREV_ZONE}`;

/**
 * E.g. "November 4, 2023"
 */
export const LONG_MONTH_DAY_YEAR = "LLLL d, yyyy";

/**
 * Whenever we want "now", we should use this function.
 * Then, we can temporarily edit this function during local development for
 * testing purposes (by adding or subtracting hours such as via `.minus({ hours: 24 })`).
 * https://moment.github.io/luxon/api-docs/index.html#datetimenow
 */
export function now() {
  return DateTime.now();
}

type ObjectWithCreatedAt = {
  createdAt: string;
};

export function compareCreatedAtJsDateStrings(
  a: ObjectWithCreatedAt,
  b: ObjectWithCreatedAt,
) {
  return compareJsDateStrings(a.createdAt, b.createdAt);
}

export function compareLuxonDates(a: DateTime, b: DateTime) {
  return a.toMillis() - b.toMillis();
}

export function compareJsDateStrings(a: string, b: string) {
  return compareLuxonDates(
    DateTime.fromJSDate(new Date(a)),
    DateTime.fromJSDate(new Date(b)),
  );
}

export const getMillisecondsOfTimeComponent = (d: DateTime): number => {
  return (
    d.get("hour") * 1000 * 60 * 60 +
    d.get("minute") * 1000 * 60 +
    d.get("second") * 1000 +
    d.get("millisecond")
  );
};

/** Ignoring the date components, sort DateTimes purely based on their time of day */
export function compareLuxonTimes(a: DateTime, b: DateTime): number {
  return getMillisecondsOfTimeComponent(a) - getMillisecondsOfTimeComponent(b);
}

export function roundDateTimeDownToNearestHalfHour(input: DateTime): DateTime {
  const output = input.startOf("hour");
  if (input.minute >= 30) {
    output.set({ minute: 30 });
  }
  return output;
}

export function roundDateTimeUpToNearestHalfHour(input: DateTime): DateTime {
  const output = input.startOf("hour");
  if (input.minute < 30) {
    return output.set({ minute: 30 });
  } else {
    return output.plus({ hour: 1 }).set({ minute: 0 });
  }
}

/**
 * @returns {string} e.g. "2023-05-23 17:00" in the user's local timezone
 */
export function getYyyyMmDdHhMm(dateString: string): string {
  const dateTime = DateTime.fromISO(dateString);
  return dateTime.toFormat(DATE_FORMAT_YEAR_TO_MINUTE);
}

/**
 * @returns {string} e.g. "16 hours ago"
 */
export function toRelative(dateString: string): string {
  const dateTime = DateTime.fromISO(dateString);
  return dateTime.toRelative() ?? "";
}

export const dayOfWeekEnumLookup: { [key: number]: DayOfWeek } = {
  [1]: DayOfWeek.MON,
  [2]: DayOfWeek.TUE,
  [3]: DayOfWeek.WED,
  [4]: DayOfWeek.THU,
  [5]: DayOfWeek.FRI,
  [6]: DayOfWeek.SAT,
  [7]: DayOfWeek.SUN,
};

export const dayOfWeekLabelLookup = {
  [DayOfWeek.MON]: "Mondays",
  [DayOfWeek.TUE]: "Tuesdays",
  [DayOfWeek.WED]: "Wednesdays",
  [DayOfWeek.THU]: "Thursdays",
  [DayOfWeek.FRI]: "Fridays",
  [DayOfWeek.SAT]: "Saturdays",
  [DayOfWeek.SUN]: "Sundays",
};

/**
 * Returns the next X weekdays starting beyond tomorrow.
 */
export function getNextAvailableWeekdays(count: number): DateTime[] {
  const today = now().startOf("day");
  const firstAvailableDay = today.plus({ days: 2 }).startOf("day");

  const dates: DateTime[] = [];

  let currentDate = firstAvailableDay;

  while (dates.length < count) {
    // I.e. is not a Saturday or Sunday
    if (!currentDate.weekdayLong.startsWith("S")) {
      dates.push(currentDate);
    }
    currentDate = currentDate.plus({ days: 1 });
  }

  return dates;
}

/**
 * This function is used to adjust a date to be relative to the current week.
 * For example, if the date is a Tuesday a month ago, it will be adjusted to the Tuesday of this week.
 * @deprecated shouldnt be necessary anymore after switching startDateTime -> dayOfWeek+timeOfDay
 * */
export function adjustDateRelativeToThisWeek(dateToAdjust: DateTime) {
  const mondayOfThisWeek = now().startOf("week");

  return mondayOfThisWeek.plus({
    day: dateToAdjust.weekday - 1,
    hour: dateToAdjust.hour,
    minute: dateToAdjust.minute,
  });
}

/**
 * Returns an array of DateTime objects representing the unique days in the input array. Ignores the time component.
 */
export function getUniqueDays(dateTimes: DateTime[]): DateTime[] {
  const uniqueDays: DateTime[] = [];
  dateTimes.forEach((dateTime) => {
    if (!uniqueDays.some((uniqueDay) => uniqueDay.hasSame(dateTime, "day"))) {
      uniqueDays.push(dateTime);
    }
  });
  return uniqueDays;
}

/**
 * Returns an array of DateTime objects representing the unique times in the input array. Ignores the date component.
 */
export function getTimes(
  dateTimes: DateTime[],
  chosenDay: DateTime,
): DateTime[] {
  const times: DateTime[] = [];
  dateTimes.forEach((dateTime) => {
    if (chosenDay.hasSame(dateTime, "day")) {
      times.push(dateTime);
    }
  });
  return times;
}

/**
 * Returns an array of hourly slots starting at 9am and ending at 5pm for the provided day.
 */
export function getHourlySlots(day: DateTime): DateTime[] {
  // Set the starting hour to 9am and the ending hour to 5pm
  const startHour = 9;
  const endHour = 17;

  // Create an array to store the hourly slots
  const hourlySlots: DateTime[] = [];

  // Create a DateTime object for the provided day at 9am
  let currentSlot = day.set({ hour: startHour, minute: 0, second: 0 });

  // Loop through the hours and add each slot to the array
  while (currentSlot.hour < endHour) {
    hourlySlots.push(currentSlot);
    currentSlot = currentSlot.plus({ hours: 1 });
  }

  return hourlySlots;
}

/**
 * This is a temporary function that ultimately will be replaced by a database query that finds
 * actual teacher availability.
 * Currently it just returns hourly time slots with start times from 9am to 4pm inclusive on the next 7 business
 * days (Mon-Fri) starting after tomorrow.
 */
export function getAvailableDateTimesForTeacherWithoutAvailabilitySetup(): DateTime[] {
  const availableDateTimes: DateTime[] = [];

  const days = getNextAvailableWeekdays(7);
  days.forEach((day) => {
    availableDateTimes.push(...getHourlySlots(day));
  });

  return availableDateTimes;
}

export function getBrowserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Returns the resolved browserTimeZone and also the currentTimeZone (which is the
 * user's saved preference if available or otherwise the browser's timezone).
 */
export function getCurrentTimeZone(
  userPreferencesTimezone: string | null | undefined,
) {
  const browserTimeZone = getBrowserTimeZone();
  const currentTimeZone = userPreferencesTimezone ?? browserTimeZone;
  return { browserTimeZone, currentTimeZone };
}

/**
 * Returns time zone abbreviation, such as "EDT".
 */
export function getTimeZoneAbbreviation(timeZone: string): string {
  const date = new Date();
  const timeZoneAbbreviation = Intl.DateTimeFormat(undefined, {
    timeZone,
    timeZoneName: "short",
  }).formatToParts(date);

  const abbreviationPart = timeZoneAbbreviation.find(
    (part) => part.type === "timeZoneName",
  );

  return abbreviationPart?.value ?? "";
}

export function getDateWithoutTime(
  chosenTimeSlotUnvalidated: string | undefined,
): DateTime | null {
  return chosenTimeSlotUnvalidated
    ? DateTime.fromISO(
        chosenTimeSlotUnvalidated.substring(
          0,
          DATE_FORMAT_HYPHENATED_YYYY_MM_DD.length,
        ),
      ).startOf("day")
    : null;
}

export function isTimeSpecified(
  chosenDateTimeSlotUnvalidated: string | undefined,
): boolean {
  return chosenDateTimeSlotUnvalidated
    ? chosenDateTimeSlotUnvalidated.length >
        DATE_FORMAT_HYPHENATED_YYYY_MM_DD.length
    : false; // e.g. "2023-09-24 09:00"
}

/**
 * The day portion of chosenTimeSlotUnvalidated is an available day in the availableDateTimes array.
 */
export function isDayOfferedInAvailableDateTimes(
  chosenDateTimeSlotUnvalidated: string,
  availableDateTimes: DateTime[],
): boolean {
  // This step is to make time zones the same:
  const dateTime = DateTime.fromFormat(
    chosenDateTimeSlotUnvalidated,
    DATE_FORMAT_HYPHENATED_YYYY_MM_DD,
  );

  return availableDateTimes.some((availableDateTime) => {
    return (
      availableDateTime.toFormat(DATE_FORMAT_HYPHENATED_YYYY_MM_DD) ===
      dateTime.toFormat(DATE_FORMAT_HYPHENATED_YYYY_MM_DD)
    );
  });
}

/**
 * The chosenTimeSlotUnvalidated is in the availableDateTimes array.
 */
export function isDateTimeOfferedInAvailableDateTimes(
  chosenTimeSlotUnvalidated: string,
  availableDateTimes: DateTime[],
): boolean {
  // This step is to make time zones the same:
  const dateTime = DateTime.fromFormat(
    chosenTimeSlotUnvalidated,
    DATE_FORMAT_YEAR_TO_MINUTE,
  );

  return availableDateTimes.some((availableDateTime) => {
    return (
      availableDateTime.toFormat(DATE_FORMAT_YEAR_TO_MINUTE) ===
      dateTime.toFormat(DATE_FORMAT_YEAR_TO_MINUTE)
    );
  });
}

export function getStripeExpiryFormat(dateTime: DateTime): string {
  const expiry = dateTime.toFormat("MM / yy"); // https://moment.github.io/luxon/#/formatting?id=table-of-tokens
  return expiry;
}

/**
 * returns E.g. "1:30 PM EDT"
 */
export function getLessonTimeFormatted(
  dateTime: Date | DateTime,
  timeZone?: Zone | string | null | undefined,
): string {
  const luxonDateTime =
    dateTime instanceof Date ? DateTime.fromJSDate(dateTime) : dateTime;
  const zone = timeZone ?? UTC;
  return luxonDateTime.setZone(zone).toFormat(TIME_WITH_ABBREV_ZONE);
}

/**
 * returns E.g. "Wednesday, August 6, 2014 9:00 am EST"
 */
export function getChosenTimeSlotFormatted(
  timeSlot: Date,
  timeZone: null | string,
): string {
  return DateTime.fromJSDate(timeSlot)
    .setZone(timeZone ?? UTC)
    .toFormat(LONG_DAY_COMMA_DATE_TIME_WITH_ZONE);
}

/**
 * Gets the start and end datetimes formatted as necessary.
 * This is useful for AddToCalendarButton.
 */
export function getMeetAndGreetStartAndEndDateTimes(
  chosenTimeSlot: DateTime,
  meetAndGreetDurationMinutes: number,
) {
  const startDateTime = chosenTimeSlot;

  const endDateTime = startDateTime.plus({
    minutes: meetAndGreetDurationMinutes,
  });

  const result = {
    startDate: startDateTime.toFormat(DATE_FORMAT_HYPHENATED_YYYY_MM_DD),
    startTime: startDateTime.toFormat(DATE_FORMAT_HH_MM),
    endTime: endDateTime.toFormat(DATE_FORMAT_HH_MM),
    timeZone: chosenTimeSlot.toFormat(IANA_ZONE),
  };

  return result;
}
