import {
  useConversationIdForTeachingRelationshipLazyQuery,
  useUpdateConversationIdMutation,
} from "@graphql";

/**
 * Because something could've gone wrong, there's a chance
 * we might not have a TR.conversationId so we need to ensure
 * that we've initiated it before we try to open it
 */
export const useConversationId = () => {
  const [updateConversationId] = useUpdateConversationIdMutation();
  const [getConversationId] =
    useConversationIdForTeachingRelationshipLazyQuery();

  const getOrCreateConversationId = async (
    teachingRelationshipId: string,
  ): Promise<string> => {
    try {
      const { data } = await getConversationId({
        fetchPolicy: "cache-first",
        variables: {
          teachingRelationshipId,
        },
      });

      if (!data) {
        throw new Error("Failed to find Teaching Relationship");
      }

      const { teachingRelationship } = data;

      if (teachingRelationship.conversationId) {
        return teachingRelationship.conversationId;
      }

      const response = await updateConversationId({
        variables: {
          input: {
            conversationId: teachingRelationshipId,
            teachingRelationshipId,
          },
        },
      });

      if (!response.data) {
        throw new Error("Failed to update conversation id; missing data");
      }

      const newConversationId =
        response.data.updateConversationId.teachingRelationship.conversationId;

      if (!newConversationId) {
        throw new Error(
          "Failed to update conversation id; invalid conversationId",
        );
      }

      return newConversationId;
    } catch (err) {
      console.error("Failed to create conversationId");
      throw err;
    }
  };

  return {
    getOrCreateConversationId,
  };
};
