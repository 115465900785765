import { useEffect } from "react";

import { useRouter } from "next/router";

import {
  NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
} from "../constants/config";
import { BASE_URL } from "../helpers/routes";

/**
 * If we're in preview mode and the hostname includes the branch name, redirect to BASE_URL, which is current commit of the branch.
 * VercelGitBranchURLs have:
 *  prefix: `opus-git-`
 *  a potentially substringed git branch name with dashes instead of problematic characters like slashes.
 *  suffix: `${vercelDeploymentSha6Chars}-fortelessons`
 *  @example `opus-git-fix-display-available-devices-in-d-ecfc5c-fortelessons.vercel.app`
 */
const usePreviewBranchRedirect = () => {
  const router = useRouter();
  const VERCEL_GIT_BRANCH_URL_PREFIX = "opus-git-";
  const isPreviewEnvironment = NEXT_PUBLIC_VERCEL_ENV === "preview";
  const isOnMain = NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "main";
  const sanitizedTruncatedCommitRef =
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF.replaceAll("/", "-").slice(0, 30);
  const vercelGitBranchUrlPart =
    VERCEL_GIT_BRANCH_URL_PREFIX + sanitizedTruncatedCommitRef;

  useEffect(() => {
    const hasHashInUrl = window.location.hostname.includes(
      vercelGitBranchUrlPart,
    );

    if (isPreviewEnvironment && !isOnMain && hasHashInUrl) {
      // eslint-disable-next-line no-console
      console.log("Redirecting because of preview branch URL");
      router.replace(BASE_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default usePreviewBranchRedirect;
