import { type PropsWithChildren } from "react";
import { createPortal } from "react-dom";

import { useReducedMotion } from "@react-spring/web";
import clsx from "clsx";
import { useLockedBody } from "usehooks-ts";

export function DrawerModal({
  children,
  className = "",
  isDrawerOpen,
  onClose,
}: {
  className?: string;
  isDrawerOpen: boolean;
  onClose: () => void;
} & PropsWithChildren) {
  const prefersReducedMotion = useReducedMotion();

  useLockedBody(isDrawerOpen, "root");

  return createPortal(
    <>
      {isDrawerOpen && (
        <div
          onClick={onClose}
          className={clsx("bg-forteTransparentOverlay fixed inset-0 z-30", {
            "animate-fade-in": !prefersReducedMotion,
          })}
        />
      )}
      <div
        tabIndex={-1}
        className={clsx(
          "overflow-y-auto fixed top-0 right-0 z-40 shadow-md h-full rounded-tl-md border-l border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 w-full max-w-6xl",
          {
            "translate-x-full": !isDrawerOpen,
            "transition-transform": !prefersReducedMotion,
          },
          className,
        )}
      >
        {children}
      </div>
    </>,
    document.body,
  );
}
