import { randomBytes } from "crypto";
import compact from "lodash/compact";

export const DEFAULT_PASSWORD_LENGTH = 18;
const LINE_BREAKS_REGEX = /(?:\r\n|\r|\n)/g;

/**
 * Replaces line breaks with <br /> tags.
 * See another implementation option at https://stackoverflow.com/a/7467863/
 */
export function replaceLineBreaksWithBr(input: string): string {
  return input.replace(LINE_BREAKS_REGEX, "<br />");
}

export function splitOnLineBreaks(paragraphs: string): string[] {
  return paragraphs.split(LINE_BREAKS_REGEX);
}

/**
 * E.g. Inputs 'exampleString[slug]', 'exampleString[secondSlug]', and 'exampleString' all return 'exampleString' if replaceValue is ''.
 */
export function replaceTrailingSquareBracketsChunk(
  inputString: string,
  replaceValue: string,
): string {
  const regex = /\[[^\]]*\]$/; // Matches text in square brackets at the end of the string. See test cases.
  return inputString.replace(regex, replaceValue);
}

export function getFullName(
  givenName: string,
  familyName: string | null,
): string {
  return compact([givenName, familyName]).join(" ");
}

export function getGivenNameAndInitialOfFamilyName(
  givenName: string,
  familyName: string | null,
): string {
  const lastInitialAndPeriod = familyName ? `${familyName.charAt(0)}.` : "";
  return compact([givenName, lastInitialAndPeriod]).join(" ");
}

export function generateRandomPasswordConformingToPasswordPolicy(
  length: number,
): string {
  if (length < 8) {
    throw new Error("Password must be 8 or more characters");
  }

  const upperCaseLettersCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerCaseLettersCharset = upperCaseLettersCharset.toLowerCase();
  const numbersCharset = "0123456789";
  const symbolsCharset = "!@#$%";
  const fullCharset = `${upperCaseLettersCharset}${lowerCaseLettersCharset}${numbersCharset}${symbolsCharset}`;

  const randomBytesArray = randomBytes(length);

  let wipPassword = "";
  for (let i = 0; i < length; i += 1) {
    // Use the random byte as an index to pick a character:
    wipPassword += getEntryFromCorpus(i, fullCharset);
  }

  /*
   * Make sure the password has at least one entry each of the lowercase, uppercase, numbers, and symbols sets
   * By hardcoding positions we're making this less secure, but this is just meant to be a temporary shortlived password anyway
   */

  function getEntryFromCorpus(index: number, corpus: string): string {
    return corpus.charAt(randomBytesArray[index] % corpus.length);
  }

  const hardcodedPasswordPrefix = [
    getEntryFromCorpus(0, lowerCaseLettersCharset), // passwords often are required to start with a letter, so we'll make sure that's the case
    getEntryFromCorpus(1, upperCaseLettersCharset),
    getEntryFromCorpus(2, numbersCharset),
    getEntryFromCorpus(3, symbolsCharset),
  ].join("");

  // Overwrite the first few characters of the password so that we know we fulfill all requirements
  // Without doing this, you could get unlucky and generate a password that e.g. contains no numbers and fails the AWS password policy
  const finalPassword =
    hardcodedPasswordPrefix + wipPassword.slice(hardcodedPasswordPrefix.length);

  return finalPassword;
}
