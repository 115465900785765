import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Client, Conversation } from "@twilio/conversations";

import { useGenerateTwilioChatTokenMutation } from "@graphql";

type ChatClientManagerContextType = {
  getClientForIdentityUser: (identityUserId: string) => null | Client;
  getConversationsForIdentityUser: (
    identityUserId: string,
  ) => Array<Conversation>;
  createClientForUser: (userId: string) => void;
};

const ChatClientManagerContext = createContext<ChatClientManagerContextType>(
  null as any,
);

export function useChatClientManager(identityUserId: string) {
  const context = useContext(ChatClientManagerContext);
  if (context === null) {
    throw new Error(
      "useChatClientManager() must be used within a <ChatClientManagerProvider>",
    );
  }

  const client = context.getClientForIdentityUser(identityUserId) || null;
  const conversations =
    context.getConversationsForIdentityUser(identityUserId) || [];

  useEffect(() => {
    if (!client) {
      console.debug(
        `[chat] twilio client didnt exist for ${identityUserId}; initing`,
      );
      context.createClientForUser(identityUserId);
    }
  }, [identityUserId, client]);

  return {
    ...context,
    client,
    conversations,
  };
}

export function ChatClientManagerProvider({ children }: PropsWithChildren) {
  const [clientMap, setClientMap] = useState<Record<string, Client>>({});

  const [listenerMap, setClientListeners] = useState<
    Record<string, Record<string, (...args: Array<any>) => void>>
  >({});

  const [conversationsMap, setConversationsMap] = useState<
    Record<string, Array<Conversation>>
  >({});

  const [generateTwilioChatToken] = useGenerateTwilioChatTokenMutation();

  useEffect(() => {
    return () => {
      Object.keys(listenerMap).forEach((identityUserId) => {
        const client = getClientForIdentityUser(identityUserId);
        const listenersForClient = listenerMap[identityUserId];
        if (!client || !listenersForClient) {
          return;
        }
        client.off("tokenExpired", listenersForClient.updateToken);
        client.off("tokenAboutToExpire", listenersForClient.updateToken);
        client.off("conversationAdded", listenersForClient.conversationAdded);
        client.off("connectionError", listenersForClient.connectionError);
        client.off("initFailed", listenersForClient.initFailed);
        client.off("initialized", listenersForClient.initialized);
      });
    };
  }, [listenerMap]);

  const newConversationAdded = useCallback(
    async (userId: string, conversation: Conversation) => {
      setConversationsMap((prev) => {
        return {
          ...prev,
          [userId]: [...(prev[userId] || []), conversation],
        };
      });

      // const lastReadMessageIndex = conversation.lastReadMessageIndex;
      // if (!lastReadMessageIndex) {
      //   await conversation.updateLastReadMessageIndex(0);
      // }
    },
    [],
  );

  function getClientForIdentityUser(identityUserId: string): null | Client {
    const client: undefined | Client = clientMap[identityUserId];
    if (!client) {
      return null;
    }
    return client;
  }

  function getConversationsForIdentityUser(
    identityUserId: string,
  ): Array<Conversation> {
    const conversations = conversationsMap[identityUserId] || [];
    return conversations;
  }

  async function createClientForUser(userId: string): Promise<void> {
    const { data } = await generateTwilioChatToken({
      variables: {
        userId,
      },
    });

    if (!data) {
      throw new Error("Failed to create token");
    }

    const identityUserId = data.generateTwilioChatToken.identityUser.id;
    const newClient = new Client(data.generateTwilioChatToken.token, {
      // logLevel: "debug",
    });

    const listenersForClient = {
      updateToken: () => updateToken(identityUserId),
      conversationAdded: (conversation: Conversation) =>
        newConversationAdded(userId, conversation),
      initialized: () => console.log("[chat] initialized"),
      initFailed: () => console.error("[chat] initFailed"),
      connectionError: () => console.error("[chat] connectionError"),
    };

    newClient.on("tokenExpired", listenersForClient.updateToken);
    newClient.on("tokenAboutToExpire", listenersForClient.updateToken);
    newClient.on("conversationAdded", listenersForClient.conversationAdded);
    newClient.on("connectionError", listenersForClient.connectionError);
    newClient.on("initFailed", listenersForClient.initFailed);
    newClient.on("initialized", listenersForClient.initialized);

    setClientListeners((prev) => ({
      ...prev,
      [identityUserId]: listenersForClient,
    }));

    setClientMap((prev) => ({
      ...prev,
      [identityUserId]: newClient,
    }));
  }

  const updateToken = useCallback(
    async (identityUserId: string) => {
      console.log("[chat] updateToken()");
      const client = clientMap[identityUserId];
      if (!client) {
        return;
      }

      try {
        const { data } = await generateTwilioChatToken({
          variables: {
            userId: identityUserId,
          },
        });
        if (data) {
          await client.updateToken(data.generateTwilioChatToken.token);
        }
      } catch (error) {
        console.error("[chat] updateToken error", error);
      }
    },
    [clientMap],
  );

  const providerValue: ChatClientManagerContextType = {
    getClientForIdentityUser,
    getConversationsForIdentityUser,
    createClientForUser,
  };

  return (
    <ChatClientManagerContext.Provider value={providerValue}>
      {children}
    </ChatClientManagerContext.Provider>
  );
}
