import { Button } from "flowbite-react";
import { useRouter } from "next/router";

import { FrontDoorLayout } from "@layouts/FrontDoorLayout";
import { Heading } from "@riffs/Heading";
import { StyledText } from "@riffs/StyledText";

import { SIGN_OUT } from "../helpers/routes";

import { useUpdateUserTermsOfUseMutation } from "@graphql";

export function TermsOfUseRoadblock() {
  const [updateUserTermsOfUse] = useUpdateUserTermsOfUseMutation();
  const router = useRouter();

  return (
    <div className="flex flex-col gap-3">
      <Heading level={2} className="mb-3">
        Please Review
      </Heading>
      <div>
        <StyledText>
          Please review and accept our updated{" "}
          <a
            target="_blank"
            href="https://www.fortelessons.com/terms-of-use/"
            rel="noopener noreferrer"
            className="underline text-forteBlue dark:text-blue-400"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://www.fortelessons.com/privacy-policy/"
            rel="noopener noreferrer"
            className="underline text-forteBlue dark:text-blue-400"
          >
            Privacy Policy
          </a>
          {"."}
        </StyledText>
        <div className="flex flex-row gap-2 mt-4 justify-center">
          <Button
            color="light"
            onClick={() => {
              router.push(SIGN_OUT);
            }}
          >
            Cancel
          </Button>
          <Button
            color="neon"
            onClick={async () => {
              updateUserTermsOfUse();
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
}

export function TermsOfUseRoadblockAsPage() {
  return (
    <FrontDoorLayout
      heading="Please Review"
      lowerLine="Our Terms of Use and Privacy Policy have changed."
    >
      <TermsOfUseRoadblock />
    </FrontDoorLayout>
  );
}
