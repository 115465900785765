import React from "react";

import { type Conversation } from "@twilio/conversations";
import { Alert } from "flowbite-react";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";

import { useChatClientManager } from "@components/ChatClientManagerContext";
import { useMaybeViewerContext } from "@components/MaybeViewerProvider";
import { getDisambiguatingTeacherSelectorLabel } from "@util/disambiguatingTeacherSelectorLabel";
import { getDisambiguatingLearnerSelectorLabel } from "@util/getDisambiguatingLearnerSelectorLabel";
import { possessionApostrophize } from "@util/util";

import { type RoleT, RoleTypeEnum } from "../../../constants/role";
import { ConversationChannel } from "./ConversationChannel";

import {
  type DisambiguatingTeachingRelationshipFragment,
  useAllConversationsForOrgQuery,
  useAllConversationsForTeacherQuery,
} from "@graphql";

const getConversationTitle = (
  teachingRelationship: DisambiguatingTeachingRelationshipFragment,
  teachingRelationships: Array<DisambiguatingTeachingRelationshipFragment>,
  currentRole: RoleT,
  viewerId: string,
) => {
  return currentRole?.type === RoleTypeEnum.STUDENT_OR_PARENT
    ? getDisambiguatingTeacherSelectorLabel(
        teachingRelationship,
        teachingRelationships,
        viewerId,
        {
          includeChildName: false,
        },
      )
    : getDisambiguatingLearnerSelectorLabel(
        teachingRelationship,
        teachingRelationships,
        {},
      );
};

const formatGroupLabel = (
  option: DisambiguatingTeachingRelationshipFragment,
) => {
  return `${possessionApostrophize(
    option.learner.givenNameAndInitialOfFamilyName,
  )} teachers`;
};

export const ChannelList = () => {
  const { currentRole, viewer } = useMaybeViewerContext();

  const { conversations: allConversations } = useChatClientManager(
    viewer?.id || "",
  );

  const organizationId =
    currentRole?.type === RoleTypeEnum.STUDENT_OR_PARENT
      ? currentRole.organizationId
      : null;

  const { data: allConversationsForOrgData } = useAllConversationsForOrgQuery({
    skip: !organizationId,
    variables: {
      organizationId: organizationId as string,
    },
  });

  const { data: allConversationsForTeacherData } =
    useAllConversationsForTeacherQuery({
      skip: Boolean(organizationId),
    });

  if (!viewer || !currentRole) {
    return null;
  }

  const teachingRelationships =
    allConversationsForOrgData?.organization.teachingRelationships ||
    allConversationsForTeacherData?.viewerAllTeachingRelationships.filter(
      (tr) => tr.teacher.id === viewer.id,
    ) ||
    [];

  const groupedConversations = groupBy(teachingRelationships, (option) =>
    organizationId ? option.learner.id : null,
  );
  const showHeader = Object.keys(groupedConversations).length > 1;

  const sortGroups = (
    groupedItems: Record<
      string,
      Array<DisambiguatingTeachingRelationshipFragment>
    >,
  ): Array<Array<DisambiguatingTeachingRelationshipFragment>> => {
    return sortBy(groupedItems, (optionGroup) => {
      const sampleOption = optionGroup[0];
      return sampleOption.learner.id === viewer.id
        ? ""
        : sampleOption.learner.givenNameAndInitialOfFamilyName;
    });
  };

  const sortItemsInGroup = (
    options: Array<DisambiguatingTeachingRelationshipFragment>,
  ): Array<DisambiguatingTeachingRelationshipFragment> => {
    return sortBy(
      options,
      (option) => {
        return currentRole?.type === RoleTypeEnum.STUDENT_OR_PARENT
          ? option.teacher.givenNameAndInitialOfFamilyName
          : option.learner.givenNameAndInitialOfFamilyName;
      },
      (option) => option.instrument.name,
      (option) => option.studio.name,
    );
  };

  const conversationsByUniqueName = keyBy(
    allConversations,
    (conv) => conv.uniqueName,
  ) as Record<string, Conversation>;

  const sortedGroups = sortGroups(groupedConversations);

  return (
    <div>
      {sortedGroups.length === 0 && (
        <Alert className="m-4">
          {`No ${
            currentRole.type === RoleTypeEnum.STUDENT_OR_PARENT
              ? "teachers"
              : "students"
          } to display.`}
        </Alert>
      )}
      {sortedGroups.map((itemsInGroup) => {
        const sampleOption = itemsInGroup[0];
        return (
          <React.Fragment key={sampleOption.id}>
            {showHeader && (
              <div
                className="font-bold text-sm px-2 py-1 mt-2"
                key={sampleOption.id}
              >
                {formatGroupLabel(sampleOption)}
              </div>
            )}
            {sortItemsInGroup(itemsInGroup).map((tr) => {
              const twilioConversation =
                conversationsByUniqueName[tr.id] || undefined;

              const conversationTitle = getConversationTitle(
                tr,
                teachingRelationships,
                currentRole,
                viewer.id,
              );
              return (
                <ConversationChannel
                  key={tr.id}
                  teachingRelationship={tr}
                  conversationTitle={conversationTitle}
                  twilioConversation={twilioConversation}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};
