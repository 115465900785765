import { type Media, type Message } from "@twilio/conversations";
import { Spinner } from "flowbite-react";
import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

import { useMaybeViewerContext } from "@components/MaybeViewerProvider";
import { AvatarForUser } from "@riffs/Avatar";
import { Icon } from "@riffs/Icon";
import { NameForUser } from "@riffs/NameForUser";

export const renderTime = (date: Date | null) => {
  if (date) {
    const dateTime = DateTime.fromJSDate(date);
    return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  }

  return null;
};

const getBackgroundAndTextColor = (
  _isILE: boolean,
  viewerId: string,
  messageAuthorId: string | null,
) => {
  if (viewerId === messageAuthorId) {
    return "bg-forteBlue-80";
  }

  return "bg-gray-300";
};

const getMetadataColor = (
  _isILE: boolean,
  viewerId: string,
  messageAuthorId: string | null,
) => {
  if (viewerId === messageAuthorId) {
    return "text-gray-700";
  }

  return "text-gray-700";
};

export const FileBubble = ({
  downloadHandler,
  downloadingFileIds,
  isILE,
  media,
  messageObj,
}: {
  downloadHandler: (media: Media) => Promise<void>;
  downloadingFileIds: string[];
  isILE: boolean;
  media: Media;
  messageObj: Message;
}) => {
  const { viewer } = useMaybeViewerContext();

  if (!viewer) {
    return null;
  }

  const renderIcon = () => {
    const isPresent = downloadingFileIds.includes(media?.sid || "");

    if (isPresent) {
      return <Spinner size="xs" />;
    }

    if (messageObj.author === viewer.id) {
      return <Icon.CloudUpload />;
    } else {
      return <Icon.CloudDownload />;
    }
  };

  const messageAuthorId = getMessageAuthorId(messageObj);
  const isMessageFromViewer = messageAuthorId === viewer.id;

  return (
    <div
      className={twMerge(
        "flex my-1 text-sm",
        isMessageFromViewer && "self-end",
      )}
    >
      <div
        className={twMerge(
          "flex w-full",
          isMessageFromViewer && "flex-row-reverse",
        )}
      >
        {!isMessageFromViewer && (
          <div className="flex w-1/5 px-2 flex-col-reverse">
            {messageAuthorId === "forte" ? (
              <Icon.MessageAlert className="p-2 bg-gray-200 rounded-full text-xl" />
            ) : (
              <AvatarForUser userId={messageAuthorId} rounded />
            )}
          </div>
        )}

        <div
          className={twMerge(
            "flex flex-col",
            isMessageFromViewer ? "ml-2 items-end" : "items-start mr-2",
            // max width is dependent on whether avatar is shown
            isMessageFromViewer ? "max-w-[92%]" : "max-w-[80%]",
            isMessageFromViewer ? "pointer-events-none" : "cursor-pointer",
          )}
          onClick={() => downloadHandler(media)}
        >
          <div
            className={twMerge(
              "text-xs px-1",
              isMessageFromViewer ? "text-right ml-4 mr-1" : "text-left mr-4",
              getMetadataColor(isILE, viewer.id, messageAuthorId),
            )}
          >
            {messageAuthorId && !isMessageFromViewer && (
              <>
                {messageAuthorId === "forte" ? (
                  <span className="font-bold mr-1.5">Forte</span>
                ) : (
                  <NameForUser
                    className="font-bold mr-1.5"
                    userId={messageAuthorId}
                  />
                )}
              </>
            )}
            <span className="text-gray-400">
              {renderTime(messageObj?.dateCreated)}
            </span>
          </div>
          <div
            className={twMerge(
              "px-2 py-1 whitespace-pre-wrap break-all rounded-xl",
              getBackgroundAndTextColor(isILE, viewer.id, messageAuthorId),
              isMessageFromViewer && "mr-2",
            )}
          >
            <div className="flex">
              <span className="my-auto mx-3.5">{renderIcon()}</span>
              <span>{media?.filename}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function getMessageAuthorId(message: Message): null | string {
  const messageAuthorId =
    message.attributes &&
    typeof message.attributes === "object" &&
    !Array.isArray(message.attributes) &&
    typeof message.attributes.sentBy === "string"
      ? message.attributes.sentBy
      : message.author;
  return messageAuthorId;
}

const urlify = (text: string | null) => {
  const urlRegex =
    /((https?|ftps?):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?)/gu;
  return text
    ? text.replaceAll(urlRegex, '<a href="$1" target="_blank">$1</a>')
    : "";
};

export const TextBubble = ({
  isILE,
  messageObj,
}: {
  isILE: boolean;
  messageObj: Message;
}) => {
  const { viewer } = useMaybeViewerContext();

  if (!viewer) {
    return null;
  }

  const messageAuthorId = getMessageAuthorId(messageObj);

  const isMessageFromViewer = messageAuthorId === viewer.id;

  return (
    <div
      className={twMerge(
        "flex my-1 text-sm w-full",
        isMessageFromViewer && "self-end",
      )}
    >
      <div
        className={twMerge(
          "flex w-full",
          isMessageFromViewer && "flex-row-reverse",
        )}
      >
        {!isMessageFromViewer && (
          <div className="flex shrink-0 w-14 pl-2 flex-col-reverse">
            {messageAuthorId === "forte" ? (
              <Icon.MessageAlert className="p-2 bg-gray-200 rounded-full text-xl" />
            ) : (
              <AvatarForUser userId={messageAuthorId} rounded />
            )}
          </div>
        )}

        <div
          className={twMerge(
            "flex flex-col",
            isMessageFromViewer ? "ml-2 items-end" : "items-start mr-2",
            // max width is dependent on whether avatar is shown
            isMessageFromViewer ? "max-w-[92%]" : "max-w-[80%]",
          )}
        >
          <div
            className={twMerge(
              "text-xs px-1",
              isMessageFromViewer ? "text-right mr-1" : "text-left mr-4",
              getMetadataColor(isILE, viewer.id, messageAuthorId),
            )}
          >
            {messageAuthorId && !isMessageFromViewer && (
              <>
                {messageAuthorId === "forte" ? (
                  <span className="font-bold mr-1.5">Forte</span>
                ) : (
                  <NameForUser
                    className="font-bold mr-1.5"
                    userId={messageAuthorId}
                  />
                )}
              </>
            )}
            <span className="text-gray-400">
              {renderTime(messageObj?.dateCreated)}
            </span>
          </div>
          <div
            className={twMerge(
              "px-2 py-1 break-words whitespace-pre-wrap rounded-xl inline-block max-w-full",
              getBackgroundAndTextColor(isILE, viewer.id, messageAuthorId),
              isMessageFromViewer && "mr-2",
            )}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: urlify(messageObj.body),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const UploadingBubble = ({ isILE: _isILE }: { isILE: boolean }) => {
  return (
    <div className="flex self-end my-1 text-sm cursor-pointer">
      <div
        className={twMerge(
          "flex items-center justify-center",
          "p-2 pr-3 mr-2",
          "rounded-2xl",
          "bg-forteBlue text-gray-900",
          "whitespace-pre-wrap break-all",
        )}
      >
        <span className="my-auto mx-2">
          <Spinner size="xs" />
        </span>
        <span>Uploading....</span>
      </div>
    </div>
  );
};
